import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SensorTypesService {


  SensorTypesAllUrl= "sensor_types/";
  AddSensorTypeUrl= "sensor_types/";
  DeleteSensorTypeUrl= "sensor_types/";
  UpdateSensorTypeUrl= "sensor_types/";


  constructor(private http: HttpClient) { 
  }
  
  getAllSensorTypes(): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(`${environment.ApiUrl+this.SensorTypesAllUrl}`,{ headers: headers });
    
  }

  addSensorType(data:any): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.post<any>(`${environment.ApiUrl+this.AddSensorTypeUrl}`,data,{ headers: headers });
    
  }

  updateSensorType(data:any): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.put<any>(`${environment.ApiUrl+this.UpdateSensorTypeUrl+data.id}`,data,{ headers: headers });
    
  }

  deleteSensorType(Sensor_id:number): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.delete<any>(`${environment.ApiUrl+this.DeleteSensorTypeUrl+Sensor_id}`,{ headers: headers });
    
  }
}
