import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectService } from '../../services/project/project.service';
import { ZonesService } from '../../services/zone/zones.service';
import { MatIconModule } from '@angular/material/icon';
import { UserService } from '../../services/users/users.service';

@Component({
  selector: 'app-assigne-role',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
  MatIconModule],
  templateUrl: './assigne-role.component.html',
  styleUrl: './assigne-role.component.scss'
})
export class AssigneRoleComponent {
  form: FormGroup;
  isCreate: boolean = true;
  roles:any=[];
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private dialogRef: MatDialogRef<AssigneRoleComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.form = this.fb.group({
      role: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.userService.getAllRoles().subscribe((roles) => {
      this.roles = roles;
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.valid) {
      const role = this.form.get('role')?.value;
        this.userService.assigneRole(this.data.id,role).subscribe(() => {
          this.dialogRef.close(true);
        });
    }
  }
}
