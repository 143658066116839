<div class="mx-5">
  <div class="w-full">
    <div class="flex justify-center mb-5">
      <div class="inline-flex space-x-2">
        <button *ngFor="let filter of ['lasthour', 'lastday', 'lastweek', 'lastmonth', 'lastyear', 'all']"
                [ngClass]="{'bg-primary-500 text-white': currentFilter === filter}"
                (click)="currentFilter = filter; updateCharts()"
                class="px-4 py-2 border rounded hover:bg-primary-300 transition">
          {{ filter | titlecase }}
        </button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="isActive; else shimmer">
    <div class="grid grid-cols-3 gap-4 my-3">
      <div *ngFor="let chartOptions of chartOptionsList; let i = index" class="p-4 rounded-lg">
        <highcharts-chart 
          [Highcharts]="Highcharts"
          [options]="chartOptions"
          style="width: 100%; height: 270px; display: block;">
        </highcharts-chart>
      </div>
    </div>
  </ng-container>

  <!-- Shimmer Loading Template -->
  <ng-template #shimmer>
    <div class="grid grid-cols-3 gap-4 my-3">
      <div *ngFor="let n of [1,2,3,4,5,6,7,8]" class=" bg-white rounded">
        <div class="w-full h-[270px] bg-gray-300 rounded relative overflow-hidden p-3">
          <div class="absolute inset-0 bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 animate-shimmer"></div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
