<div class="max-w-4xl mx-auto p-4 my-8">
  <div class="bg-white rounded-2xl shadow-lg overflow-hidden">
    <!-- Profile Header -->
    <div class="p-6 sm:p-8">
      <div class="flex flex-col sm:flex-row items-center gap-6">
        <!-- Avatar -->
        <div class="w-24 h-24 bg-primary-600 rounded-full flex items-center justify-center">
          <span *ngIf="userProfile" class="text-3xl font-semibold text-white">{{ userInitials }}</span>
        </div>

        <!-- User Info -->
        <div class="text-center sm:text-left flex-1">
          <h1 class="text-2xl font-bold text-gray-900">{{ userProfile.userName }}</h1>
          <p class="text-gray-500 mt-1">{{ userProfile.email }}</p>
        </div>

        <!-- Edit Button -->
        <button
          (click)="toggleEdit()"
          class="px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors"
        >
          {{ isEditing ? 'Cancel' : 'Edit Profile' }}
        </button>
      </div>
    </div>

    <div class="w-full h-px bg-gray-200"></div>

    <!-- Profile Content -->
    @if (!isEditing) {
      <div class="p-6 sm:p-8">
        <!-- Info Grid -->
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <!-- Username -->
          <div class="flex items-start gap-4">
            <div class="w-10 h-10 rounded-lg bg-primary-50 flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-primary-600" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
              </svg>
            </div>
            <div>
              <p class="text-sm font-medium text-gray-500">Username</p>
              <p class="mt-1 text-gray-900">{{ userProfile.username }}</p>
            </div>
          </div>

          <!-- Phone -->


          <!-- Email Verification -->
          <div class="flex items-start gap-4">
            <div class="w-10 h-10 rounded-lg bg-primary-50 flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-primary-600" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M2.166 4.999A11.954 11.954 0 0010 1.944 11.954 11.954 0 0017.834 5c.11.65.166 1.32.166 2.001 0 5.225-3.34 9.67-8 11.317C5.34 16.67 2 12.225 2 7c0-.682.057-1.35.166-2.001zm11.541 3.708a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg>
            </div>
            <div>
              <p class="text-sm font-medium text-gray-500">Email Verification</p>
              <div class="mt-1 flex items-center">
                <span [class]="userProfile.enabled ? 'text-green-600' : 'text-red-600'">
                  {{ userProfile.enabled ? 'Verified' : 'Not Verified' }}
                </span>
              </div>
            </div>
          </div>

          <div class="flex items-start gap-4">
            <div class="w-10 h-10 rounded-lg bg-primary-50 flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-primary-600" viewBox="0 0 20 20" fill="currentColor">
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
              </svg>
            </div>
            <div>
              <p class="text-sm font-medium text-gray-500">First Name</p>
              <p class="mt-1 text-gray-900">{{ userProfile.firstName || '' }}</p>
            </div>
          </div>
          <div class="flex items-start gap-4">
            <div class="w-10 h-10 rounded-lg bg-primary-50 flex items-center justify-center">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-primary-600" viewBox="0 0 20 20" fill="currentColor">
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
              </svg>
            </div>
            <div>
              <p class="text-sm font-medium text-gray-500">Last Name</p>
              <p class="mt-1 text-gray-900">{{ userProfile.lastName || '' }}</p>
            </div>
          </div>
        </div>

        <!-- Roles Section -->
        <div class="mt-8">
          <h3 class="text-lg font-semibold text-gray-900 mb-4">Roles</h3>
          <div class="flex flex-wrap gap-2">
            @for (role of userProfile.roles; track role) {
              <span class="px-3 py-1 bg-primary-50 text-primary-700 rounded-full text-sm font-medium">
                {{ role.name }}
              </span>
            }
          </div>
        </div>
      </div>
    } @else {
      <!-- Edit Form -->
      <form [formGroup]="editForm" (ngSubmit)="onSubmit()" class="p-6 sm:p-8">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
          <!-- Name -->
          <div class="">
            <label class="block text-sm font-medium text-gray-700 mb-2">First Name</label>
            <input
              type="text"
              formControlName="firstName"
              class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            />
            @if (editForm.get('firstName')?.hasError('required') && editForm.get('firstName')?.touched) {
              <p class="mt-1 text-sm text-red-600">First Name is required</p>
            }
          </div>

          <div>
            <label class="block text-sm font-medium text-gray-700 mb-2">Last Name</label>
            <input
              type="text"
              formControlName="lastName"
              class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            />
            <p *ngIf="editForm.get('lastName')?.hasError('required') && editForm.get('lastName')?.touched" class="mt-1 text-sm text-red-600">
              Last Name is required
            </p>
          </div>

          <div class="col-span-2">
            <label class="block text-sm font-medium text-gray-700 mb-2">Email</label>
            <input
              type="email"
              formControlName="email"
              class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            />
            <p *ngIf="editForm.get('email')?.hasError('required') && editForm.get('email')?.touched" class="mt-1 text-sm text-red-600">
              Email is required
            </p>
            <p *ngIf="editForm.get('email')?.hasError('email') && editForm.get('email')?.touched" class="mt-1 text-sm text-red-600">
              Invalid email format
            </p>
          </div>

          <!-- Password Change -->
          <div>
            <label class="block text-sm font-medium text-gray-700 mb-2">New Password</label>
            <input
              type="password"
              formControlName="password"
              class="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
            />
            @if (editForm.get('password')?.hasError('minlength')) {
              <p class="mt-1 text-sm text-red-600">Password must be at least 8 characters</p>
            }
          </div>

          <!-- Submit Buttons -->
          <div class="col-span-2 flex justify-end gap-4 mt-6">
            <button
              type="button"
              (click)="toggleEdit()"
              class="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              [disabled]="!editForm.valid || editForm.pristine"
              class="px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors disabled:bg-gray-300 disabled:cursor-not-allowed"
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    }
  </div>
</div>
