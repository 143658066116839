import { MatCardModule } from '@angular/material/card';
import { Component,inject } from '@angular/core';
import {  MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIcon } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, RouterModule} from '@angular/router';
import { CommonModule } from '@angular/common';
import { CardService } from '../../services/card/card.service';
import { AddCardComponent } from '../../components/add-card/add-card.component';
import { CustomDialogComponent } from '../../shared/components/custom-dialog/custom-dialog.component';

@Component({
  selector: 'app-cards',
  standalone: true,
  imports: [RouterModule, CommonModule,MatCardModule,MatButtonModule,MatIcon],
  templateUrl: './cards.component.html',
  styleUrl: './cards.component.scss'
})
export class CardsComponent {

  zoneId:any = null;
  zoneName:string | null = "";
  cards:any = null;
  breakpoint:number = 4;

  readonly deleteDialog = inject(MatDialog);
  readonly dialog = inject(MatDialog);

constructor(private readonly route: ActivatedRoute,
  private readonly cardservice: CardService,private readonly _snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.zoneId = params.get('zone_id');
      this.zoneName = params.get('zone_name');
      setTimeout(() => {
        this.getCards();
      }, 1000);
    });
  }


  openCreateDialog(): void {
    this.dialog.open(AddCardComponent, {
      width: '75vw',
      data : {zoneId:this.zoneId}
    }).afterClosed().subscribe((result) => {
        this.getCards();
    });
  }

  openDeleteDialog(card_id:number,enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.deleteDialog.open(CustomDialogComponent, {
      panelClass: 'responsive-dialog',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        card_id: card_id,
        title: "Delete Card",
        message: "Are you sure you want to delete this card?",
       }
    }).afterClosed().subscribe((result) => {
        this.deleteCard(card_id);
    });
  }
  deleteCard(card_id:number){
    this.cardservice.deleteCard(card_id).subscribe(
      (response) => {
        if(response?.message){
          this.openSnackBar(response.message,"close")
        }else{
          this.openSnackBar("Error Server Try again","close")
        }
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  getCards(){

    this.cardservice.getAllCards(this.zoneId).subscribe(
      (response) => {
        if (response) {
          this.cards = response;
        }
      }
    );
  }

}
