import { createDateSimpleColumn, createSimpleColumn, createTextColumn, createUrlColumn } from "../../shared/config-table";
import { TableColumn } from "../../shared/models/table-columns.model";

export const displayedColumns: TableColumn[] = [
  createDateSimpleColumn('Date From', 'dateFrom',true),
  createSimpleColumn('Time From','timeFrom',true),
  createDateSimpleColumn('Date To', 'dateTo',true),
  createSimpleColumn('Time To','timeTo',true),
  createSimpleColumn('Duration','duration',true),
  createTextColumn('Description', 'description', true),
  createTextColumn('Cam Ref', 'camRef', true),
  createTextColumn('Project', 'project', true),
  createTextColumn('Zone', 'zone', true),
  createDateSimpleColumn('Created At', 'createdAt',true),
  createUrlColumn('URL', 'url', true),

  {
    name: 'Actions',
    position: 'flex-end',
    isSortable: false,
    headerType: 'simple',
    cellType: 'action',
    actions: [
    {
      label: 'Deactivate',
      materialIconName: 'remove_red_eye',
      action: 'deactivate'
    }]
  },
];
