
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SensorTypesService } from '../../services/sensor-types/sensor_types.service';
import { SensorType, Types } from '../../core/types/Sensor';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-add-sensor-type',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule],
    templateUrl: './add-sensor-type.component.html',
    styleUrl: './add-sensor-type.component.scss'
  })
  export class AddSensorTypeComponent {

  form: FormGroup;
  iscreate:boolean = true;
  sensorTypes: any;
  types = Object.values(Types);
  configSnakebar = {
    duration: 30000,
    panelClass: ['warning-snackbar'],
    verticalPosition: 'top'
  }

  constructor(
    private _snackBar: MatSnackBar,
    private sensortypesService:SensorTypesService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddSensorTypeComponent>,
    @Inject(MAT_DIALOG_DATA) public data:   SensorType
  ) {

    //this.sensorTypes = Object.values(SensorType);

    this.form = this.formBuilder.group({
      id: [null, Validators.nullValidator],
      name: ['', Validators.required],
      unit: ['', Validators.required],
      type: [null, Validators.nullValidator],
      max_value: [null, Validators.nullValidator],
      min_value: [null, Validators.nullValidator],
      alert_value: [null, Validators.nullValidator]
    });

  }

  ngOnInit(): void {

    if (this.data) {
      this.form.patchValue(this.data);
      this.iscreate = false;
    }

    this.getSensortypes();

  }


  getSensortypes(){

    this.sensortypesService.getAllSensorTypes().subscribe(
      (response) => {
        if (response) {
          this.sensorTypes = response;
        }
      }
    );
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {

    if (this.iscreate) {
      if (this.form.valid) {
        this.sensortypesService.addSensorType(this.form.value).subscribe(
          (response) => {
            this.openSnackBar(response.message,"close");
            this.dialogRef.close(true);
          }
        );
      }
    } else {
      if (this.form.valid) {
        this.sensortypesService.updateSensorType(this.form.value).subscribe(
          (response) => {
            this.openSnackBar(response.message,"close");
            this.dialogRef.close(true);
          }
        );
      }
    }

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

}
