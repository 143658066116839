import { Component,inject, signal } from '@angular/core';
import {  MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { AlertsService } from '../../services/alerts/alerts.service';
import { SharedTableComponent } from '../../shared/components/shared-table/shared-table.component';
import { MatIconModule } from '@angular/material/icon';
import { CustomDialogComponent } from '../../shared/components/custom-dialog/custom-dialog.component';
import { displayedColumns } from './table-config';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [
     CommonModule,MatButtonModule,SharedTableComponent,MatIconModule],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent {

  dataSource :any
  sensorTypes :any
  recordings:any = null;
  readonly deleteDialog = inject(MatDialog);
  readonly dialog = inject(MatDialog);

  skip:number = 0
  limit:number = 15

  displayedColumns= displayedColumns;
  totalItems = 0;
  loadingTable = signal(false);

  constructor(private alertsService:AlertsService,private _snackBar: MatSnackBar){

  }
  ngOnInit() {
    this.loadingTable.set(true);
    setTimeout(() => {
      this.getNotifications();
    }, 500);
  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }


  getNotifications(){
    this.alertsService.getAllAlerts(this.skip,this.limit).subscribe(
      (response) => {
        if (response) {
          this.sensorTypes = response;
          this.dataSource = response;
          this.loadingTable.set(false);
          this.totalItems= 25;
        }

      }
    );
  }


  openDeleteDialog(alert_id:number,enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.deleteDialog.open(CustomDialogComponent, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { alert_id: alert_id,
        title: 'Desactivate Alert Type',
        message: 'Are you sure you want to desactivate this alert ?',
       }
    }).afterClosed().subscribe((result) => {
      if(result){
        this.deleteSensorType(alert_id);
      }
    });

  }

  deleteSensorType(alert_id:number){
    this.alertsService.desactivate(alert_id).subscribe(
      (response) => {
        if(response.message){
          this.openSnackBar(response.message,"close")
          this.getNotifications();

        }
      }
    );
  }

  changedPage(event: PageEvent) {
    console.log('event ',event);

  }

  changedSort(sort: any) {
    console.log('sort ',sort)

  }

  rowSelect(event: any) {
    console.log('event row select ',event)
  }

  actionOnTable(event: { action: string, row: any }) {
    console.log('event action on table',event)
  }

  onChangeFilter(event:{column:string,value: string[]}) {
    console.log('event ',event)
  }
}

