import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidemenuService {

  private menuSubject = new BehaviorSubject<boolean>(false);
  public menu$ = this.menuSubject.asObservable();

  constructor() {}

  ToggleMenu(active: boolean): void {
    this.menuSubject.next(active);
  }

}
