import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({providedIn: 'root'})
export class IconsService
{
    /**
     * Constructor
     */
    constructor()
    {
        const domSanitizer = inject(DomSanitizer);
        const matIconRegistry = inject(MatIconRegistry);

        // Register icon
        matIconRegistry.addSvgIcon('add_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/add.svg'));
        matIconRegistry.addSvgIcon('arrow_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow.svg'));
        matIconRegistry.addSvgIcon('calendar_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/calendar.svg'));
        matIconRegistry.addSvgIcon('camera_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/camera.svg'));
        matIconRegistry.addSvgIcon('cameraSensor_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/cameraSensor.svg'));
        matIconRegistry.addSvgIcon('card_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/card.svg'));
        matIconRegistry.addSvgIcon('clock_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/clock.svg'));
        matIconRegistry.addSvgIcon('company_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/company.svg'));
        matIconRegistry.addSvgIcon('delete_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/delete.svg'));
        matIconRegistry.addSvgIcon('edit_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/edit.svg'));
        matIconRegistry.addSvgIcon('email_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/email.svg'));
        matIconRegistry.addSvgIcon('emptyF_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/emptyFolder.svg'));
        matIconRegistry.addSvgIcon('filter_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/filter.svg'));
        matIconRegistry.addSvgIcon('full_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/fullScreen.svg'));
        matIconRegistry.addSvgIcon('grid_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/grid.svg'));
        matIconRegistry.addSvgIcon('zone_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/zone.svg'));

        matIconRegistry.addSvgIcon('list_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/list.svg'));
        matIconRegistry.addSvgIcon('logout_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/logout.svg'));

        matIconRegistry.addSvgIcon('notification_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/notification_header.svg'));

        matIconRegistry.addSvgIcon('project_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/project.svg'));

        matIconRegistry.addSvgIcon('projects_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/projects.svg'));
        matIconRegistry.addSvgIcon('recording_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/recording.svg'));

        matIconRegistry.addSvgIcon('search_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/search.svg'));
        matIconRegistry.addSvgIcon('sensor_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/sensor.svg'));
        matIconRegistry.addSvgIcon('sensorTypes_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/sensortypes.svg'));
        matIconRegistry.addSvgIcon('settings_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/settings.svg'));



        matIconRegistry.addSvgIcon('zoomOut_svg', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/zoomOut.svg'));
    }
}
