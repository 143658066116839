<mat-dialog-content class="p-0 h-full w-full">
  <div class=" px-4 pb-4 pt-5 sm:p-6 sm:pb-4 flex flex-col">

    <ngx-image-viewer id="imgView"
     [src]="data.images"
     [(index)]="data.imageIndex"></ngx-image-viewer>

  </div>
</mat-dialog-content>

