import { createDateSimpleColumn, createSimpleColumn } from "../../shared/config-table";
import { TableColumn } from "../../shared/models/table-columns.model";

export const displayedColumns: TableColumn[] = [
  createSimpleColumn('Name', 'name',false),
  createSimpleColumn('Unit','unit',false),
  createSimpleColumn('MinValue','min_value',true),
  createSimpleColumn('MaxValue','max_value',true),
  createDateSimpleColumn('Created At', 'created_at', true),
  {
    name: 'Actions',
    position: 'flex-end',
    isSortable: false,
    headerType: 'simple',
    cellType: 'action',
    actions: [
      {
        label: 'Update',
        materialIconName: 'edit',
        action: 'update'
      },
      {
        label: 'Delete',
        materialIconName: 'remove',
        action: 'delete'
      },
      {
        label: 'Disable alerts',
        materialIconName: 'notifications_off',
        action: 'deactivate'
      }]
  },
];
