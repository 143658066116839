import { Component } from '@angular/core';
import {  MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardActions, MatCardContent, MatCardFooter, MatCardImage } from '@angular/material/card';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { HighchartsChartModule } from 'highcharts-angular';
import * as Highcharts from 'highcharts';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SensorService } from '../../../services/sensor/sensor.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule } from '@angular/forms';
import { ThemeService } from '../../../services/theme.service';

@Component({
  selector: 'app-details-sensor',
  standalone: true,
  imports: [FormsModule, MatButtonToggleModule,MatIconModule, HighchartsChartModule, MatCard, MatCardContent, MatCardActions, MatCardFooter, MatCardImage, MatGridList, MatGridTile, MatButtonModule, CommonModule],
  templateUrl: './details-sensor.component.html',
  styleUrl: './details-sensor.component.scss'
})
export class DetailsSensorComponent {
  IsDarkMode:boolean = true;

  sensors: any[] = [];  // Holds zone data
  projectId: any = null;
  receivedData: any;
  currentFilter: string = 'lastday'; // Default filter

  Highcharts: typeof Highcharts = Highcharts;
  chartOptionsList: Highcharts.Options[] = [];
  isActive:boolean = false;

  constructor(private darkMode:ThemeService,private route: ActivatedRoute, private sensorservice: SensorService) {
    HighchartsAccessibility(Highcharts);

    this.darkMode.isDarkMode$.subscribe(value =>{
      this.IsDarkMode = value;
    });



    const titleElement = document.querySelector('.highcharts-title');
    if (titleElement) {
      console.log(this.IsDarkMode);
        titleElement.classList.add(this.IsDarkMode ? 'title-dark' : 'title-light');
    }
  }

  ngOnInit() {

    this.sensors = history.state.data
    this.updateCharts(this.sensors)

    setInterval(() => {
      this.getSensorsData()
    }, 15000); //15 Sec

    setTimeout(() => {
      this.isActive = true;
    }, 1500);
  }

  getSensorsData() {
    // Fetch data for each sensor
    this.sensors.map((sensor: { id: any, data: any[] }) => {

      // Ensure sensor.data is initialized as an array
      if (!Array.isArray(sensor.data)) {
        sensor.data = [];
      }

      // Get the last insertedAt time or default to a fallback value (e.g., null or a fixed date)
      let lastInsertedAt = sensor.data.length > 0 ? sensor.data[sensor.data.length - 1].insertedAt.replace('T', ' ') : null;

      // If lastInsertedAt is available, adjust the time by subtracting 1 hour
      if (lastInsertedAt) {
        const date = new Date(lastInsertedAt);
        date.setHours(date.getHours() - 1); // Subtract one hour
        lastInsertedAt = date.toISOString().replace('T', ' ').substring(0, 19); // Reformat the date if needed
      }

      // Call the service to get new data from the last inserted time
      if (sensor?.id) {
        this.sensorservice.getAllData(sensor.id, lastInsertedAt).subscribe((sensor_data) => {
          if (sensor_data && Array.isArray(sensor_data)) {
            // Append the new data to the existing data array
            sensor.data.push(...sensor_data); // Spread operator to add new data items
          }
          // Update the chart after new data is received
          this.updateChartSeries(this.sensors);
        });
      }

    });
  }



  updateCharts(data: any[] = this.sensors) {

    this.chartOptionsList = data.map((sensor: any) => {

        const sensorData = Array.isArray(sensor.data)
            ? sensor.data.map((entry: any) => [
                new Date(new Date(entry.insertedAt).setHours(new Date(entry.insertedAt).getHours() + 2)).getTime(), // Convert insertedAt to a timestamp
                entry.value,  // Sensor value
            ])
            : [];

        const filteredData = Array.isArray(sensorData) ? this.applyFilter(sensorData) : [];

        return {
            title: { text: `Sensor: ${sensor.name}` },
            series: [
                {
                    id: sensor.id,
                    name: sensor.name,
                    data: filteredData,  // Ensure data is in [timestamp, value] format
                    type: 'line' as Highcharts.SeriesLineOptions['type'],
                    turboThreshold: 5000,  // Optimizes rendering of large datasets
                    marker: {
                        enabled: false  // Disables markers for each point
                    }
                }
            ],
            accessibility: {
                announceNewData: {
                    enabled: true,
                    minAnnounceInterval: 15000,
                    announcementFormatter: function (allSeries, newSeries, newPoint) {
                        if (newPoint) {
                            return 'New point added. Value: ' + newPoint.y;
                        }
                        return false;
                    }
                }
            },
            xAxis: {
                type: 'datetime',  // Ensure X-axis is time-based
                title: { text: 'Time' },
                minTickInterval: 3600 * 1000,  // Increase tick interval to 1 hour to prevent crowding
                minRange: 1000  // Minimum range to prevent zooming too close
            },
            yAxis: {
                title: { text: 'Sensor Value' },
                min: 0  // Ensures the Y-axis starts from 0
            },
            tooltip: {
                xDateFormat: '%Y-%m-%d %H:%M:%S',  // Format the time in tooltip
                shared: true  // Allows shared tooltip for multiple series
            },
            plotOptions: {
                line: {
                    marker: {
                        enabled: false // Optional: disables markers for cleaner lines
                    },
                    connectNulls: true  // Connects null points in case of missing data
                }
            }
        };
    });
  }


  applyFilter(data: [number, number][]) {

    const oneHour = 60 * 60 * 1000; // One hour in milliseconds
    const now = new Date().getTime(); // Current time in milliseconds (local time)

    switch (this.currentFilter) {

      case 'lasthour':
        return data.filter(([timestamp]: [number, any]) => {
          // Convert the timestamp from UTC to local time
          return now - timestamp <= oneHour; // Filter data from the last hour
        });

      case 'lastday':
        return data.filter(([timestamp]: [number, any]) => {
          return now - timestamp <= oneHour * 24; // Filter data from the last 24 hours
        });

      case 'lastweek':
        return data.filter(([timestamp]: [number, number]) => {
          return now - timestamp <= oneHour * 24 * 7; // Filter data from the last 7 days
        });

      case 'lastmonth':
        return data.filter(([timestamp]: [number, number]) => {
          return now - timestamp <= oneHour * 24 * 30; // Filter data from the last 30 days
        });

      case 'lastyear':
        return data.filter(([timestamp]: [number, number]) => {
          return now - timestamp <= oneHour * 24 * 365; // Filter data from the last year
        });

      case 'all':
        return data.filter(([timestamp]: [number, number]) => {
          return now - timestamp <= oneHour * 24 * 365 * 5; // Filter data from the last 5 years
        });

      default:
        return data;
    }
  }


  updateChartSeries(data: any[]) {
    data.forEach((sensor: any) => {
      // Find the chart associated with the sensor by its ID
      const chart = Highcharts.charts.find((c) => c && c.options && c.options.series && c.options.series[0].id === sensor.id);

      if (chart && chart.series[0]) {
        const sensorData = Array.isArray(sensor.data)
          ? sensor.data.map((entry: any) => [
            new Date(new Date(entry.insertedAt).setHours(new Date(entry.insertedAt).getHours() + 2)).getTime(), // Convert insertedAt to a timestamp
            entry.value,  // Sensor value
          ])
          : [];

        const filteredData = Array.isArray(sensorData) ? this.applyFilter(sensorData) : [];

        // Update the chart's series with new data
        chart.series[0].setData(filteredData, true); // true forces a redraw of the chart
      }
    });
  }



}
