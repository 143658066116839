import { Component,inject, signal } from '@angular/core';
import {  MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog} from '@angular/material/dialog';
import { SensorTypesService } from '../../services/sensor-types/sensor_types.service';
import { MatMenuModule } from '@angular/material/menu';
import { AddSensorTypeComponent } from '../../components/add-sensor-type/add-sensor-type.component';
import { ThemeService } from '../../services/theme.service';
import { SharedTableComponent } from '../../shared/components/shared-table/shared-table.component';
import { FormsModule } from '@angular/forms';
import { CustomDialogComponent } from '../../shared/components/custom-dialog/custom-dialog.component';
import { displayedColumns } from './table-config';

@Component({
  selector: 'app-sensor-types',
  standalone: true,
  imports: [MatIconModule,
     CommonModule,FormsModule,MatButtonModule,MatMenuModule,SharedTableComponent],
  templateUrl: './sensor-types.component.html',
  styleUrl: './sensor-types.component.scss'
})

export class SensorTypesComponent {

  IsDarkMode:boolean = false;
  searchText: string = '';
  dataSource :any
  sensorTypes :any
  recordings:any = null;
  readonly deleteDialog = inject(MatDialog);
  readonly dialog = inject(MatDialog);

  constructor(private readonly darkMode:ThemeService,private readonly sensortypesService:SensorTypesService,private readonly _snackBar: MatSnackBar){

  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    this.darkMode.isDarkMode$.subscribe(value =>{
      this.IsDarkMode = value;
    });

    this.loadingTable.set(true);
    setTimeout(() => {
      this.getSensortypes();
    }, 500);
  }

  displayedColumns = displayedColumns;

  totalItems = 0;
  loadingTable = signal(false);



  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  getSensortypes(){

    this.sensortypesService.getAllSensorTypes().subscribe(
      (response) => {
        if (response) {
          this.sensorTypes = response;
          this.dataSource = response;
          this.loadingTable.set(false);
          this.totalItems= 50;
        }
      }
    );
  }


  openCreateDialog(): void {
     this.dialog.open(AddSensorTypeComponent, {
      width: '75vw',
      panelClass: this.IsDarkMode ? 'dark' : ''
    }).afterClosed().subscribe((result) => {
        this.getSensortypes();
    });
  }

  openUpdateDialog(data:any): void {
    this.dialog.open(AddSensorTypeComponent, {
     width: '75vw',
     data:data,
     panelClass: this.IsDarkMode ? 'dark' : ''
   }).afterClosed().subscribe((result) => {
       this.getSensortypes();
   });
 }


  openDeleteDialog(sensorType_id:number,enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.deleteDialog.open(CustomDialogComponent, {
      panelClass: 'responsive-dialog',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { sensorType_id: sensorType_id,
        title: "Delete Sensor Type",
        message: "Are you sure you want to delete this sensor type?",
       }
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.deleteSensorType(sensorType_id);
      }
    });

  }
  deleteSensorType(sensorType_id:number){
    this.sensortypesService.deleteSensorType(sensorType_id).subscribe(
      (response) => {
        if(response.message){
          this.openSnackBar(response.message,"close")
          this.getSensortypes();

        }
      }
    );
  }

  changedPage(event: any) {
    console.log('event ',event);

  }

  changedSort(sort: any) {
    console.log('sort ',sort)

  }

  rowSelect(event: any) {
    console.log('event row select ',event)
  }

  actionOnTable(event: { action: string, row: any }) {
    console.log('event action on table',event);

    switch (event.action) {
      case 'update':
        this.openUpdateDialog(event.row);
        break;
      case 'delete':
        this.openDeleteDialog(event.row.id,'300ms','300ms');
        break;
      case 'deactivate':
        this.openUpdateDialog(event.row);
        break;
      default:
        break;
    }
  }

  onChangeFilter(event:{column:string,value: string[]}) {
    console.log('event ',event)
  }
}
