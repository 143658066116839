import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RedmineService {

  redmineUrl= "redmine/tasks/";


  constructor(private http: HttpClient) {
  }

  getAllRedmineTickets(): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(`${environment.ApiUrl+this.redmineUrl}`,{ headers: headers });

  }

}
