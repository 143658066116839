import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  ProjectsAllUrl= "projects/";
  AddProjectUrl= "projects/";
  DeleteProjectUrl= "projects/";
  UpdateProjectUrl= "projects/";
  statisticsUrl= "all/count/";
  userUrl= "users/";


  constructor(private http: HttpClient) {
  }

  getAllProjects(): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(`${environment.ApiUrl+this.ProjectsAllUrl}`,{ headers: headers });

  }

  getAllProjectsByUser(userId:any): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(`${environment.ApiUrl+this.userUrl+userId}/projects`,{ headers: headers });

  }

  addProject(data:any): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.post<any>(`${environment.ApiUrl+this.AddProjectUrl}`,data,{ headers: headers });

  }

  updateProject(data:any): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.put<any>(`${environment.ApiUrl+this.UpdateProjectUrl+data.id}`,data,{ headers: headers });

  }

  deleteProject(project_id:number): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.delete<any>(`${environment.ApiUrl+this.DeleteProjectUrl+project_id}`,{ headers: headers });

  }

  getAllStatistics(): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(`${environment.ApiUrl+this.statisticsUrl}`,{ headers: headers });

  }


}
