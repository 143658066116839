import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment.dev';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private readonly apiUrl = 'keycloak/users/';
  private readonly rolesUrl = 'keycloak/roles/';

  constructor(private readonly http: HttpClient) { }

  getAllUsers(skip: any = 0, limit: any = 100): Observable<any> {
    const headers = new HttpHeaders({
      'accept': '*/*',
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(`${environment.ApiUrl + this.apiUrl}?skip=${skip}&limit=${limit}`, { headers: headers });
  }

  addUser(user: any): Observable<any> {
    const headers = new HttpHeaders({
      'accept': '*/*',
      'Content-Type': 'application/json'
    });

    return this.http.post<any>(`${environment.ApiUrl}users/`, user, { headers: headers });
  }

  updateUser(user: any): Observable<any> {
    const headers = new HttpHeaders({
      'accept': '*/*',
      'Content-Type': 'application/json'
    });

    return this.http.put<any>(`${environment.ApiUrl + this.apiUrl}${user.id}`, user, { headers: headers });
  }

  assigneRole(userId: any,role:any): Observable<any> {
    const headers = new HttpHeaders({
      'accept': '*/*',
      'Content-Type': 'application/json'
    });
    let roleToSend ={
      id:role.id,
      name:role.name
    }

    return this.http.post<any>(`${environment.ApiUrl + this.apiUrl+userId}/roles/`, roleToSend, { headers: headers });
  }

  assigneProject(userId: any,projectId:any): Observable<any> {
    const headers = new HttpHeaders({
      'accept': '*/*',
      'Content-Type': 'application/json'
    });

    return this.http.post<any>(`${environment.ApiUrl}users/${userId}/assign-project/${projectId}`, {}, { headers: headers });
  }


  getAllRoles(): Observable<any> {
    const headers = new HttpHeaders({
      'accept': '*/*',
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(`${environment.ApiUrl + this.rolesUrl}`, { headers: headers });
  }
}
