


<div class="max-w-[85%] mx-auto p-6">
  <h1 class="text-2xl font-bold mb-4">Dashboard</h1>
  <div class="grid grid-cols-3 gap-3 ">
    <div class=" items-center justify-between shadow-md p-4 text-white rounded-md bg-primary-400" >
      <div class="grid grid-cols-3 gap-3 ">
        <div class=" items-center space-x-2  my-auto text-start">
        <mat-icon class="mat-18 text-8xl text-center align-middle w-full h-full">grain</mat-icon>
        </div>
        <div class="grid items-center space-x-2   my-auto">
          <span class="font-bold text-2xl ">Projects  </span>
        </div>
        <div class="grid items-center space-x-2   my-auto w-full text-end">
          <span class=" rounded-full px-2 py-1 text-3xl font-extrabold">13</span>
        </div>
      </div>
    </div>
    <div class=" items-center justify-between shadow-md p-4 text-white rounded-md bg-primary-400" >
      <div class="grid grid-cols-3 gap-3 ">
        <div class=" items-center space-x-2  my-auto text-start">
        <mat-icon class="mat-18 text-8xl text-center align-middle w-full h-full">select_all</mat-icon>
        </div>
        <div class="grid items-center space-x-2   my-auto">
          <span class="font-bold text-2xl ">Zones  </span>
        </div>
        <div class="grid items-center space-x-2   my-auto w-full text-end">
          <span class=" rounded-full px-2 py-1 text-3xl font-extrabold">13</span>
        </div>
      </div>
    </div>
    <div class=" items-center justify-between shadow-md p-4 text-white rounded-md bg-primary-400" >
      <div class="grid grid-cols-3 gap-3 ">
        <div class=" items-center space-x-2  my-auto text-start">
        <mat-icon class="mat-18 text-8xl text-center align-middle w-full h-full">cameras</mat-icon>
        </div>
        <div class="grid items-center space-x-2   my-auto">
          <span class="font-bold text-2xl ">Cameras  </span>
        </div>
        <div class="grid items-center space-x-2   my-auto w-full text-end">
          <span class=" rounded-full px-2 py-1 text-3xl font-extrabold">13</span>
        </div>
      </div>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-3 mt-5">

    <div class=" items-center justify-between shadow-md p-4 text-white rounded-md bg-primary-400" >
      <div class="grid grid-cols-3 gap-3 ">
        <div class=" items-center space-x-2  my-auto text-start">
        <mat-icon class="mat-18 text-8xl text-center align-middle w-full h-full">broadcast_on_home</mat-icon>
        </div>
        <div class="grid items-center space-x-2   my-auto">
          <span class="font-bold text-2xl ">Sensors  </span>
        </div>
        <div class="grid items-center space-x-2   my-auto w-full text-end">
          <span class=" rounded-full px-2 py-1 text-3xl font-extrabold">13</span>
        </div>
      </div>
    </div>
    <div class=" items-center justify-between shadow-md p-4 text-white rounded-md bg-primary-400" >
      <div class="grid grid-cols-3 gap-3 ">
        <div class=" items-center space-x-2  my-auto text-start">
        <mat-icon class="mat-18 text-8xl text-center align-middle w-full h-full">web_stories</mat-icon>
        </div>
        <div class="grid items-center space-x-2   my-auto">
          <span class="font-bold text-2xl ">Cards  </span>
        </div>
        <div class="grid items-center space-x-2   my-auto w-full text-end">
          <span class=" rounded-full px-2 py-1 text-3xl font-extrabold">13</span>
        </div>
      </div>
    </div>
    
</div>

  <div class="grid grid-cols-2 gap-3 mt-10">
    <div class=" items-center justify-between shadow-md p-4 text-white rounded-md bg-primary-400" >
      <div class="grid grid-cols-3 gap-3 ">
        <div class=" items-center space-x-2  my-auto text-start">
        <mat-icon class="mat-18 text-8xl text-center align-middle w-full h-full">subscriptions</mat-icon>
        </div>
        <div class="grid items-center space-x-2   my-auto">
          <span class="font-bold text-2xl ">Saved Recordings   </span>
        </div>
        <div class="grid items-center space-x-2   my-auto w-full text-end">
          <span class=" rounded-full px-2 py-1 text-3xl font-extrabold">13</span>
        </div>
      </div>
    </div>
    <div class=" items-center justify-between shadow-md p-4 text-white rounded-md bg-primary-400" >
      <div class="grid grid-cols-3 gap-3 ">
        <div class=" items-center space-x-2  my-auto text-start">
        <mat-icon class="mat-18 text-8xl text-center align-middle w-full h-full">notifications</mat-icon>
        </div>
        <div class="grid items-center space-x-2   my-auto">
          <span class="font-bold text-2xl ">Alerts  </span>
        </div>
        <div class="grid items-center space-x-2   my-auto w-full text-end">
          <span class=" rounded-full px-2 py-1 text-3xl font-extrabold">13</span>
        </div>
      </div>
    </div>
  
  </div>

  <h1 class="text-2xl font-bold mb-4 mt-20">Projects</h1>
  <ng-container *ngIf="projects; else shimmer">
    <div class="grid grid-cols-3 gap-4">

      <div  class="bg-white dark:bg-gray-700 shadow-md rounded-md p-4 grid grid-cols-3   transform transition-transform duration-300 hover:scale-105"  
      *ngFor="let project of projects">

      <!-- Project Name -->
      <h2 class="font-semibold text-lg my-auto cursor-pointer" [routerLink]="['/dashboard/',project.id]">{{ project.name }}</h2>
    
      <!-- Project Details -->
      <div class="grid grid-cols-2 text-center mt-2">
        <div class="px-2 py-1 text-xs mr-2 flex">
          <span><mat-icon class="mat-18 text-3xl">select_all</mat-icon></span>
          <span class="text-xl ml-3 font-bold">{{ project.zonesCount }}</span>
        </div>
        <div class="px-2 py-1 text-xs mr-2 flex">
          <span><mat-icon class="mat-18 text-3xl">cameras</mat-icon></span>
          <span class="text-xl ml-3 font-bold">{{ project.camerasCount }}</span>
        </div>
        <div class="px-2 py-1 text-xs mr-2 flex">
          <span><mat-icon class="mat-18 text-3xl">web_stories</mat-icon></span>
          <span class="text-xl ml-3 font-bold">{{ project.cardsCount }}</span>
        </div>
        <div class="px-2 py-1 text-xs mr-2 flex">
          <span><mat-icon class="mat-18 text-3xl">broadcast_on_home</mat-icon></span>
          <span class="text-xl ml-3 font-bold">{{ project.sensorsCount }}</span>
        </div>
      </div>
    
      <!-- Right Icon -->
      <div class="flex justify-end items-center">
        <div class="cursor-pointer bg-primary-300 w-12 h-12 rounded-full flex justify-center items-center transition-transform duration-300 transform hover:scale-125">
          <mat-icon class="mat-18 text-white text-4xl w-8 h-8" [routerLink]="['/dashboard/',project.id]">keyboard_double_arrow_right</mat-icon>
        </div>
      </div>
      
    </div>
    </div>
  </ng-container>
  
  <ng-template #shimmer>
    <div class="grid grid-cols-3 gap-4">
    <div  *ngFor="let _ of [1, 2, 3, 4, 5, 6, 7, 8, 9]"
      class="bg-gray-200 dark:bg-gray-800 shadow-md rounded-md p-4 grid grid-cols-3 animate-pulse">
      <div class="h-6 bg-gray-300 dark:bg-gray-600 rounded w-3/4 mb-4 my-auto"></div>
      <div class="grid grid-cols-2 gap-4 ml-6">
        <div class="h-6 bg-gray-300 dark:bg-gray-600 rounded w-5/6"></div>
        <div class="h-6 bg-gray-300 dark:bg-gray-600 rounded w-5/6"></div>
        <div class="h-6 bg-gray-300 dark:bg-gray-600 rounded w-5/6"></div>
        <div class="h-6 bg-gray-300 dark:bg-gray-600 rounded w-5/6"></div>
      </div>
      <div class="flex justify-end mt-3">
        <div class="h-12 w-12 bg-primary-300 rounded-full"></div>
      </div>
    </div>
  </div>
 </ng-template>
 
</div>