import { FilterHeaderOption, TableColumn } from "./models/table-columns.model";

const createBaseColumn = (name: string, dataKey: string, cellType: any, headerType: any, additionalProps: Partial<TableColumn> = {}): TableColumn => ({
  name,
  dataKey,
  dataKeyDtoSort: dataKey,
  cellType,
  headerType,
  ...additionalProps,
});

export const createSimpleColumn = (name: string, dataKey: string, isSortable = true): TableColumn =>
  createBaseColumn(name, dataKey, 'text', 'simple', { isSortable });

export const createDateSimpleColumn = (name: string, dataKey: string, isSortable = true): TableColumn =>
  createBaseColumn(name, dataKey, 'date', 'simple', { isSortable });

export const createUrlColumn = (name: string, dataKey: string, isSortable = true): TableColumn =>
  createBaseColumn(name, dataKey, 'url', 'simple', { isSortable });

export const createTextColumn = (name: string, dataKey: string, isSortable = true): TableColumn =>
  createBaseColumn(name, dataKey, 'text', 'filter-input', { isSortable });

export const createFilterColumn = (name: string, dataKey: string, filterHeaderOption?: FilterHeaderOption[], singleSelect = true): TableColumn =>
  createBaseColumn(name, dataKey, 'status', 'filter', { filterHeaderOption, singleSelect });

export const createStatusColumn = (name: string, dataKey: string, isSortable = true): TableColumn =>
  createBaseColumn(name, dataKey, 'status', 'simple', { isSortable });

export const createDescriptionColumn = (name: string, dataKey: string, isSortable = true,minWidth?:any): TableColumn =>
  createBaseColumn(name, dataKey, 'text', 'simple', { isSortable,minWidth });

export const createImageColumn = (name: string, dataKey: string, isSortable = true): TableColumn =>
  createBaseColumn(name, dataKey, 'imageViewer', 'simple', { isSortable });
