import { Component,inject, signal } from '@angular/core';
import {  MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog} from '@angular/material/dialog';
import { RecordingService } from '../../services/recording/recording.service';
import { CreateRecordingComponent } from '../../components/create-recording/create-recording.component';
import { MatMenuModule } from '@angular/material/menu';
import { ThemeService } from '../../services/theme.service';
import { SharedTableComponent } from '../../shared/components/shared-table/shared-table.component';
import { PageEvent } from '@angular/material/paginator';
import { FormsModule } from '@angular/forms';
import { CustomDialogComponent } from '../../shared/components/custom-dialog/custom-dialog.component';
import { displayedColumns } from './table-config';

export interface VideoRecording {
  dateFrom: Date;
  timeFrom: string;
  dateTo: Date;
  timeTo: string;
  duration: string;
  description: string;
  camRef: string;
  project: string;
  zone: string;
  createdAt: Date;
  url: string;
}

const VIDEO_RECORDINGS: VideoRecording[] = [
  {
    dateFrom: new Date('2024-08-01'),
    timeFrom: '10:00:00',
    dateTo: new Date('2024-08-01'),
    timeTo: '11:00:00',
    duration: '1h',
    description: 'Meeting with team',
    camRef: 'Cam001',
    project: 'Project Alpha',
    zone: 'Conference Room',
    createdAt: new Date('2024-08-01T11:05:00'),
    url: 'https://example.com/videos/meeting_with_team.mp4',
  },
  {
    dateFrom: new Date('2024-08-02'),
    timeFrom: '14:00:00',
    dateTo: new Date('2024-08-02'),
    timeTo: '15:30:00',
    duration: '1h 30m',
    description: 'Security check',
    camRef: 'Cam002',
    project: 'Project Beta',
    zone: 'Entrance',
    createdAt: new Date('2024-08-02T15:35:00'),
    url: 'https://example.com/videos/security_check.mp4',
  },
  // Add more video recordings as needed
];



@Component({
  selector: 'app-recordings',
  standalone: true,
  imports: [
    MatIconModule, CommonModule,
     MatButtonModule,MatMenuModule,SharedTableComponent,FormsModule],
  templateUrl: './recordings.component.html',
  styleUrl: './recordings.component.scss'
})
export class RecordingsComponent {
  searchText: string = '';

  IsDarkMode:boolean = false;
  dataSource :any
  recordings:any = null;
  readonly deleteDialog = inject(MatDialog);
  readonly dialog = inject(MatDialog);

  displayedColumns=displayedColumns;

  totalItems = 12;
  loadingTable = signal(false);

  constructor(private readonly darkMode:ThemeService,private readonly recordingservice:RecordingService,private readonly _snackBar: MatSnackBar){

  }
  ngOnInit() {
    this.darkMode.isDarkMode$.subscribe(value =>{
      this.IsDarkMode = value;
    });
    this.loadingTable.set(true);
    setTimeout(() => {
      this.getRecordings();
    }, 500);
    this.dataSource = VIDEO_RECORDINGS;
    this.loadingTable.set(false);

  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  getRecordings() {

    // this.recordingservice.getAllRecordings().subscribe(
    //   (response) => {

    //     if (response.data) {
    //       this.recordings = response.data[0];
    //     }else{
    //       this.recordings = [];
    //     }
    //   }
    // );
  }

  openCreateDialog(): void {
     this.dialog.open(CreateRecordingComponent, {
      width: '75vw',
      panelClass: this.IsDarkMode ? 'dark' : ''
    }).afterClosed().subscribe((result) => {
        this.getRecordings();
    });
  }


  openDeleteDialog(recording_id:number,enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.deleteDialog.open(CustomDialogComponent, {
      panelClass: 'responsive-dialog',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { recording_id: recording_id,
        title: "Delete Recording",
        message: "Are you sure you want to delete this recording?",
       }
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.deleteRecording(recording_id);
      }
    });

  }

  deleteRecording(recording_id:number){
    this.recordingservice.deleteRecording(recording_id).subscribe(
      (response) => {
        if(response.message){
          this.openSnackBar(response.message,"close");
          this.getRecordings();
        }
      }
    );
  }

  changedPage(event: PageEvent) {
    console.log('event ',event);

  }

  changedSort(sort: any) {
    console.log('sort ',sort)

  }

  rowSelect(event: any) {
    console.log('event row select ',event)
  }

  actionOnTable(event: { action: string, row: any }) {
    console.log('event action on table',event)
  }

  onChangeFilter(event:{column:string,value: string[]}) {
    console.log('event ',event)
  }
}

