import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RecordingService {

  RecordingAllUrl= "recording/";
  CreateRecordingUrl= "recording/";
  DeleteRecordingUrl= "recording/";


  constructor(private http: HttpClient) { 
  }
  
  getAllRecordings(): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(`${environment.ApiUrl+this.RecordingAllUrl}`,{ headers: headers });
    
  }

  createRecording(data:any): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.post<any>(`${environment.ApiUrl+this.CreateRecordingUrl}`,data,{ headers: headers });
    
  }


  deleteRecording(recording_id:number): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.delete<any>(`${environment.ApiUrl+this.DeleteRecordingUrl+recording_id}`,{ headers: headers });
    
  }
}
