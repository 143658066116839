import { Component, signal } from '@angular/core';
import {  MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SharedTableComponent } from '../../shared/components/shared-table/shared-table.component';
import { PageEvent } from '@angular/material/paginator';
import { FormsModule } from '@angular/forms';
import { displayedColumns } from './table-config';
import { CamerasKpiService } from '../../services/cameras-kpi/cameras-kpi.service';
import { MatDialog } from '@angular/material/dialog';
import { ImageViewerDialogComponent } from '../../shared/components/image-viewer/image-viewer-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { FilterDataPipe } from '../../shared/pipes/filter-data.pipe';
import { VideoViewerDialogComponent } from '../../shared/components/video-viewer/video-viewer-dialog.component';
@Component({
  selector: 'app-cameras-event',
  standalone: true,
  imports: [
    MatIconModule, CommonModule,
     MatButtonModule,MatMenuModule,SharedTableComponent,FormsModule,FilterDataPipe],
  templateUrl: './cameras-event.component.html',
  styleUrl: './cameras-event.component.scss'
})
export class CamerasEventComponent {
  searchText: string = '';
  dataSource :any
  displayedColumns=displayedColumns;
  totalItems = 0;
  loadingTable = signal(false);
  filterText = '';

  constructor(private readonly camerasKpi:CamerasKpiService,private readonly dialog: MatDialog,private sanitizer: DomSanitizer){

  }
  ngOnInit() {

    this.getAllCamerasKpi();

  }

  getAllCamerasKpi(skip?: any,limit?: any) {
    this.loadingTable.set(true);
    this.camerasKpi.getAllCamerasKpi(skip,limit).subscribe(
      (response) => {
        this.dataSource = response.data;
        this.totalItems = response.total;
        this.loadingTable.set(false);
      }
    );
  }



  changedPage(event: PageEvent) {
    console.log('event ',event);
    const limit = event.pageSize;
    const skip = event.pageIndex * event.pageSize;
    this.getAllCamerasKpi(skip,limit);
  }

  changedSort(sort: any) {
    console.log('sort ',sort)

  }

  rowSelect(event: any) {
    console.log('event row select ',event)
  }

  actionOnTable(event: { action: string, row: any }) {
    console.log('event action on table',event)
    if (event.action === 'viewImage') {
      this.openImageViewer(event.row.image_path);
    }

  }

  onChangeFilter(event:{column:string,value: string[]}) {
    console.log('event ',event)
  }

  openImageViewer(imageUrl: string) {
    this.dialog.open(VideoViewerDialogComponent, {
      panelClass: 'responsive-dialog-image',
      data: {images: [this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl)],imageIndex: 0}
    });
  }
}
