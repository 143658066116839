import { MatCardModule } from '@angular/material/card';
import { ChangeDetectionStrategy, Component,Inject,inject } from '@angular/core';
import {  MatButtonModule } from '@angular/material/button';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIcon } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { ActivatedRoute, RouterModule} from '@angular/router';
import { CommonModule } from '@angular/common';
import { CardService } from '../../services/card/card.service';
import { AddCardComponent } from '../../components/add-card/add-card.component';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-cards',
  standalone: true,
  imports: [RouterModule, CommonModule, MatGridList,MatGridTile,MatCardModule,MatButtonModule,MatIcon],
  templateUrl: './cards.component.html',
  styleUrl: './cards.component.scss'
})
export class CardsComponent {

  zoneId:any = null;
  zoneName:string | null = "";
  cards:any = null;
  breakpoint:number = 4;
  IsDarkMode:boolean = false;

  readonly deleteDialog = inject(MatDialog);
  readonly dialog = inject(MatDialog);

constructor(private darkMode:ThemeService,private route: ActivatedRoute,private cardservice: CardService) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.zoneId = params.get('zone_id');
      this.zoneName = params.get('zone_name');
      setTimeout(() => {
        this.getCards();
      }, 1000);
    });
  }


  openCreateDialog(): void {
    this.dialog.open(AddCardComponent, {
      width: '75vw',
      panelClass: this.IsDarkMode ? 'dark' : '',
      data : {zoneId:this.zoneId}
    }).afterClosed().subscribe((result) => {
        this.getCards();
    });
  }

  openDeleteDialog(card_id:number,enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.deleteDialog.open(DialogDeleteCard, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { card_id: card_id }
    }).afterClosed().subscribe((result) => {
        this.getCards();
    });
  }

  getCards(){

    this.cardservice.getAllCards(this.zoneId).subscribe(
      (response) => {
        if (response) {
          this.cards = response;
        }
      }
    );
  }

}


@Component({
  selector: 'delete-card-dialog',
  templateUrl: '../../shared/templates/delete-card-dialog.html',
  standalone: true,
  imports: [MatButtonModule,MatDialogModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogDeleteCard {
  readonly dialogRef = inject(MatDialogRef<DialogDeleteCard>);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { card_id: number }
  ,private cardservice:CardService,private _snackBar: MatSnackBar){

  }

  deleteCard(card_id:number){
    this.cardservice.deleteCard(card_id).subscribe(
      (response) => {
        if(response && response.message){
          this.openSnackBar(response.message,"close")
          this.dialogRef.close(true);
        }else{
          this.openSnackBar("Error Server Try again","close")
          this.dialogRef.close(true);
        }
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

}
