
export type Camera = {
  thumbnailUrl?: string
  modelName?: string
  zoneId?: number|string
  location_lat?:string
  location_lng?:string
  id?: string
  model?: string
  description?: string
  adjustableCoverage?: boolean //TODO: Should add a positioning system 
  status: CameraStatus
  isOnline?: boolean
  offlineReason?: string
  lastOnlineAt?: string
  name?: string
  createdAt?: string
  [x: string]: any

}


export enum CameraStatus {
  ONLINE = "online",
  OFFLINE = "offline",
  OFFLINE_SCHEDULED = "offline_scheduled",
  WAITING = "waiting",
  UNDER_MAINTENANCE = "under_maintenance",
}


export type CameraResponsePayload = {
  data?: Camera[]
}