import { MatButtonModule } from '@angular/material/button';
import { NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Action, TableColumn } from '../../../models/table-columns.model';
import { trigger } from '@angular/animations';

@Component({
    selector: 'app-action-cell',
    templateUrl: './action-cell.component.html',
    styleUrls: ['./action-cell.component.scss'],
    standalone: true,
    imports: [
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    NgTemplateOutlet
]
})
export class ActionCellComponent {
    @Input() tableColumn: TableColumn | any;
    @Input() element: any;
    @Output() actionEmittedEvent: EventEmitter<any> = new EventEmitter();
    @ViewChild('menuTrigger') trigger: any;
    actionLabel: any = '';


    onAction(actionn: any): void {
        const act = {
            action: actionn,
            row: this.element,
        };
        this.actionEmittedEvent.emit(act);
        this.trigger.closeMenu();
    }

    menuAction(): void {
        console.log('')
    }

    showBtn(actionn: Action): any {
        return (
            !actionn.showConditionColumnName ||
            (actionn.showConditionColumnName &&
                this.element[actionn.showConditionColumnName] ===
                actionn.showConditionValue)
        );
    }

    containsActionsToHide(): boolean {
        const conditionShow = (action: any) => !this.showBtn(action);
        return !this.tableColumn.actions.every(conditionShow);
    }

    disableBtn(actionn: Action): any {
        return (
            actionn.disableConditionColumnName &&
            this.element[actionn.disableConditionColumnName] ===
            actionn.disableConditionValue
        );
    }
}
