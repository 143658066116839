import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type ThemeColor = 'indigo' | 'emerald' | 'rose' | 'amber';
export type ThemeMode = 'light' | 'dark';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private darkMode = new BehaviorSubject<boolean>(false);

  isDarkMode$ = this.darkMode.asObservable();

  constructor() {
    const savedDarkMode = localStorage.getItem('darkMode') === 'true';
    this.setDarkMode(savedDarkMode);
  }



  setDarkMode(isDark: boolean) {
    this.darkMode.next(isDark);
    localStorage.setItem('darkMode', isDark.toString());
    this.updateThemeClasses();
  }

  private updateThemeClasses() {
    const isDark = this.darkMode.value;

    document.documentElement.classList.remove('light', 'dark');
    document.documentElement.classList.add(isDark ? 'dark' : 'light');

  }
}
