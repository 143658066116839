<mat-dialog-content class="p-0 h-full w-full">
  <div class="px-4 pb-4 pt-5 sm:p-6 sm:pb-4 flex flex-col">
    <div class="relative">
      <video #videoPlayer controls class="w-full h-auto">
        <source [src]="data.videoUrl" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <div class="absolute top-4 right-4 flex space-x-2">
        <button mat-icon-button (click)="zoomIn()">
          <mat-icon>zoom_in</mat-icon>
        </button>
        <button mat-icon-button (click)="zoomOut()">
          <mat-icon>zoom_out</mat-icon>
        </button>
        <button mat-icon-button (click)="downloadVideo()">
          <mat-icon>download</mat-icon>
        </button>
      </div>
    </div>
  </div>
</mat-dialog-content>
