<div class="container mx-auto grid grid-cols-12 gap-4">

  <div class="col-span-12 mb-4" *ngIf="carouselTileItems; else shimmerSensors">
    <ngu-carousel #myCarousel [inputs]="carouselTile" [dataSource]="carouselTileItems" *ngIf="carouselTileItems.length > 0; else emptySensors">
      <ngu-tile *nguCarouselDef="let sensor">
        <div class="flex flex-col items-center text-center mx-auto w-full  ">
          <div class="text-m  font-medium">{{ sensor.name }}</div>

          <div class="text-l font-bold  my-2">
            <span *ngIf="!refresh" class="font-semibold">{{ sensor?.latest_sensor_data?.value }} {{ sensor.unit }}</span>
            <mat-spinner [diameter]="25" *ngIf="refresh"></mat-spinner>
          </div>

          <div class="w-full border-t-2 mt-2 border-primary-500 rounded"></div>
        </div>
      </ngu-tile>
    </ngu-carousel>
  </div>



  <div #videoContainer class="video-container relative col-span-9 mb-3 p-0 overflow-hidden w-full">
    <img #videoElement
         alt="MJPEG Stream"
         class="absolute inset-0"
         [style.display]="videoPresent ? 'block' : 'none'"
         (load)="onImageLoad()"
         (error)="onImageError()" />
    <div class="absolute inset-0 flex justify-center items-center" *ngIf="!videoPresent">
      <mat-spinner [diameter]="125"></mat-spinner>
    </div>
  </div>

  <div class="col-span-3 mt-5">
    <div class="text-center" *ngIf="cameras; else shimmer">
      <ng-container *ngIf="cameras.length > 0; else empty">
        <div class="mt-2">
          <button (click)="openCreateDialog()" class="w-full button_side bg-primary-500 text-white font-semibold p-2 h-10 rounded justify-center align-middle m-auto"  color="secondary">
            <mat-icon class="mt-2">add</mat-icon>
            Add New Camera
          </button>
        </div>

        <div class="mt-2">
          <button [routerLink]="['/cameraswatch',zoneId,zoneName]" routerLinkActive="router-link-active" class="w-full button_side  bg-primary-500 text-white font-semibold p-2 h-10 rounded justify-center align-middle m-auto"  color="secondary">
            <mat-icon class="mt-2">monitor</mat-icon>
            Watch All Cameras
          </button>
        </div>

        <div class="grid grid-cols-2 gap-2 mt-3" >
          <button *ngFor="let camera of cameras"  (click)="startCamera(camera)"   class="w-full text-gray-900 dark:text-gray-300 button_camera button_side font-semibold p-2 h-10 rounded justify-center align-middle m-auto border-solid border hover:bg-primary-400">
            <mat-icon class="mt-2">camera</mat-icon>
            {{camera.name}}
          </button>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="col-span-9">
    <div class=" p-2 w-full flex justify-between">
      <div class="flex space-x-2"> <!-- Added space between buttons -->
        <button  (click)="togglePlayPause()" class="bg-primary-500 text-white  rounded w-10 h-10 p-2">
          <mat-icon class="m-auto text-center align-middle text-2xl">{{ isPlaying ? 'pause_circle' : 'play_circle' }}</mat-icon>
        </button>

        <button  (click)="toggleFullScreen()" class="bg-primary-500 text-white  rounded w-10 h-10 p-2">
          <mat-icon class="m-auto text-center align-middle text-2xl">fullscreen</mat-icon>
        </button>

        <button  (click)="captureScreenshot()" class="bg-primary-500 text-white  rounded w-10 h-10 p-2" *ngIf="!takingScreen">
          <mat-icon class="m-auto text-center align-middle text-2xl">camera_alt</mat-icon>
        </button>
        <button  class="bg-primary-500 text-white  rounded w-10 h-10 p-2" *ngIf="takingScreen">
          <mat-spinner *ngIf="takingScreen" [diameter]="25"></mat-spinner>
        </button>

        <button  (click)="zoomIn()" class="bg-primary-500 text-white  rounded w-10 h-10 p-2">
          <mat-icon class="m-auto text-center align-middle text-2xl">add_circle</mat-icon>
        </button>
        <button  (click)="zoomOut()" class="bg-primary-500 text-white  rounded w-10 h-10 p-2">
          <mat-icon class="m-auto text-center align-middle text-2xl">remove_circle</mat-icon>
        </button>
        <button  (click)="zoomInit()" class="bg-primary-500 text-white  rounded w-10 h-10 p-2">
          <mat-icon class="m-auto text-center align-middle text-2xl">change_circle</mat-icon>
        </button>
      </div>
      <div class="flex justify-end  space-x-2" *ngIf="selectedCamera"> <!-- Added space between buttons -->
        <!-- Delete Button -->
        <button  (click)="openDeleteDialog(selectedCamera.id, '400ms', '200ms')" class="bg-primary-500 text-white  rounded w-10 h-10 p-2">
          <mat-icon class="m-auto text-center align-middle text-2xl">delete</mat-icon>
        </button>
        <!-- Update Button -->
        <button  (click)="openUpdateDialog()" class="bg-primary-500 text-white  rounded w-10 h-10 p-2">
          <mat-icon class="m-auto text-center align-middle text-2xl">edit</mat-icon>
        </button>
      </div>
    </div>
  </div>



  <ng-template #empty>
    <mat-card class="w-full mx-auto mt-5 text-center">
      <mat-icon class="text-2xl">cloud_off</mat-icon>
      <mat-card-content>
        There are no cameras in the selected zone
      </mat-card-content>
    </mat-card>
  </ng-template>

  <ng-template #shimmer>
    <div class="text-center">
      <div class="mt-5 px-3" *ngFor="let item of [1,2]">
        <div class="h-10 w-full bg-gray-300 rounded-lg animate-pulse"></div>
      </div>
      <div class="grid grid-cols-2 gap-2 mt-5 px-3" *ngFor="let item of [1,2,3,4]">
        <div class="h-10 w-full bg-gray-300 rounded-lg animate-pulse"></div>
        <div class="h-10 w-full bg-gray-300 rounded-lg animate-pulse"></div>
      </div>
    </div>
  </ng-template>

  <ng-template #emptySensors>
    <mat-card class="w-full mx-auto my-4 text-center">
      <mat-card-content>
        There are no sensors in this zone
      </mat-card-content>
    </mat-card>
  </ng-template>

  <ng-template #shimmerSensors>
    <div class="col-span-12 mt-5">
      <div class="flex ">
        <div  *ngFor="let item of [1,2,3,4,5,6]" class="h-24 w-5/6 mx-1 bg-gray-300 rounded-lg animate-pulse"></div>
      </div>
    </div>
  </ng-template>

</div>
