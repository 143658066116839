import { Component,ChangeDetectionStrategy,Inject,inject, signal } from '@angular/core';
import {  MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SafePipe } from '../../shared/pipes/safe.pipe';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { AlertsService } from '../../services/alerts/alerts.service';
import { SharedTableComponent } from '../../shared/components/shared-table/shared-table.component';
import { Action, TableColumn } from '../../shared/models/table-columns.model';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [
     RouterLink, CommonModule,MatButtonModule,SafePipe,SharedTableComponent,MatIconModule],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent {

  dataSource :any
  sensorTypes :any
  recordings:any = null;
  readonly deleteDialog = inject(MatDialog);
  readonly dialog = inject(MatDialog);

  skip:number = 0
  limit:number = 15

  actions : Action[] = [
    {
        label: 'deactivate',
        materialIconName: 'remove_red_eye',
        action: 'deactivate'
    }
  ];

  pageSizeOptions = [10, 20, 50];

  displayedColumns: TableColumn[] = [
    {
        name: 'ID',
        dataKey: 'id',
        dataKeyDtoSort: 'id',
        cellType: 'text',
        headerType: 'simple',
    },
    {
        name: 'Message',
        dataKey: 'message',
        dataKeyDtoSort: 'message',
        cellType: 'text',
        headerType: 'simple',
    },
    {
        name: 'Type',
        dataKey: 'alert_type',
        dataKeyDtoSort: 'alert_type',
        cellType: 'text',
        headerType: 'filter',
        filterHeaderOption:[
          {
            label: 'High',
            value: 'high'
          },
          {
            label: 'Low',
            value: 'low'
          }
        ]
    },
    {
        name: 'Value',
        dataKey: 'value',
        dataKeyDtoSort: 'value',
        cellType: 'text',
        headerType: 'simple',
        isSortable:true,
    },
    {
      name: 'Threshold',
      dataKey: 'threshold',
      dataKeyDtoSort: 'threshold',
      cellType: 'text',
      headerType: 'simple',
      isSortable:true,
    },
    {
      name: 'State',
      dataKey: 'status',
      dataKeyDtoSort: 'status',
      cellType: 'text',
      headerType: 'filter',
      filterHeaderOption:[
        {
          label: 'Active',
          value: 'active'
        },
        {
          label: 'Desactivated',
          value: 'desactivated'
        }
      ]
    },
    {
      name: 'CreatedAt',
      dataKey: 'created_at',
      dataKeyDtoSort: 'created_at',
      cellType: 'date',
      headerType: 'simple',
      isSortable:true,
    },
    {
        name: 'actions',
        position: 'flex-end',
        isSortable: false,
        headerType: 'simple',
        cellType: 'action',
        actions: this.actions
    },
  ];

  totalItems = 0;
  loadingTable = signal(false);

  constructor(private alertsService:AlertsService,private _snackBar: MatSnackBar){

  }
  ngOnInit() {
    this.loadingTable.set(true);
    setTimeout(() => {
      this.getNotifications();
    }, 500);
  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }


  getNotifications(){
    this.alertsService.getAllAlerts(this.skip,this.limit).subscribe(
      (response) => {
        if (response) {
          this.sensorTypes = response;
          this.dataSource = response;
          this.loadingTable.set(false);
        }

      }
    );
  }


  openDeleteDialog(alert_id:number,enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.deleteDialog.open(DialogDesactivateNotification, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { alert_id: alert_id }
    }).afterClosed().subscribe((result) => {
        this.getNotifications();
    });

  }

  changedPage(event: PageEvent) {
    console.log('event ',event);

  }

  changedSort(sort: any) {
    console.log('sort ',sort)

  }

  rowSelect(event: any) {
    console.log('event row select ',event)
  }

  actionOnTable(event: { action: string, row: any }) {
    console.log('event action on table',event)
  }

  onChangeFilter(event:{column:string,value: string[]}) {
    console.log('event ',event)
  }
}

@Component({
  selector: 'desactivate-alert-dialog',
  templateUrl: '../../shared/templates/desactivate-alert-dialog.html',
  standalone: true,
  imports: [MatButtonModule,MatDialogModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogDesactivateNotification {
  readonly dialogRef = inject(MatDialogRef<DialogDesactivateNotification>);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { alert_id: number }
  ,private alertService:AlertsService,private _snackBar: MatSnackBar){

  }

  deleteSensorType(alert_id:number){
    this.alertService.desactivate(alert_id).subscribe(
      (response) => {
        if(response.message){
          this.openSnackBar(response.message,"close")
          this.dialogRef.close(true);
        }
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

}
