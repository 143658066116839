
import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-icon-cell',
  templateUrl: './icon-cell.component.html',
  styleUrls: ['./icon-cell.component.scss'],
  standalone:true,
  imports: [
    MatTooltipModule
]
})
export class IconCellComponent {
  @Input() tableColumn:any;
  @Input() element:any;


}
