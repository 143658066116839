import { Component} from '@angular/core';
import {  MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardActions, MatCardContent, MatCardFooter, MatCardImage, MatCardModule } from '@angular/material/card';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { SafePipe } from '../../shared/pipes/safe.pipe';
import { HighchartsChartModule } from 'highcharts-angular';
import { ProjectService } from '../../services/project/project.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {  RouterModule } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [RouterModule, MatIconModule, MatCardModule,HighchartsChartModule, MatCard,MatCardContent,MatCardActions,MatCardFooter,MatCardImage,MatGridList,MatGridTile,MatButtonModule,SafePipe,CommonModule],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})

export class DashboardComponent {

  projects:any=null;

  constructor(private projectService:ProjectService){
  }

  ngOnInit() {
    setTimeout(() => {
      this.getProjects();
    }, 2000);
  }

  getProjects() {

    this.projectService.getAllProjects().subscribe(
      (response) => {
      this.projects = response;
      }
    );
  }

  goToProject(id:any){
  }

}
