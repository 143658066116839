import { createSimpleColumn } from "../../shared/config-table";
import { TableColumn } from "../../shared/models/table-columns.model";

export const displayedColumns: TableColumn[] = [
  createSimpleColumn('Username', 'username', true),
  createSimpleColumn('Email', 'email', true),
  createSimpleColumn('First Name', 'firstName', true),
  createSimpleColumn('Last Name', 'lastName', true),
  createSimpleColumn('Enabled', 'enabled', true),
  {
    name: 'Actions',
    position: 'flex-end',
    isSortable: false,
    headerType: 'simple',
    cellType: 'action',
    actions: [
    {
      label: 'assigne role',
      materialIconName: 'remove_red_eye',
      action: 'role'
    },
    {
      label: 'assigne site',
      materialIconName: 'remove_red_eye',
      action: 'project'
    }]
  },
];
