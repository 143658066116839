import { Component, Inject, ViewChildren } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { ImageViewerComponent, ImageViewerModule,
  } from "ngx-image-viewer-3";


@Component({
  selector: 'app-image-viewer-dialog',
  standalone: true,
  imports: [MatDialogModule,MatButtonModule,

    CommonModule,MatIcon,ImageViewerModule],
  templateUrl: './image-viewer-dialog.component.html',
  styleUrl: './image-viewer-dialog.component.scss'
})
export class ImageViewerDialogComponent {
  @ViewChildren('imgView') imgView!: ImageViewerComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any) {}

}
