import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SensorService {


  SensorsAllUrl= "sensors/cards/";
  AddSensorUrl= "sensors/";
  DeleteSensorUrl= "sensors/";
  UpdateSensorUrl= "sensors/";
  GetDataUrl= "sensors/data";


  constructor(private http: HttpClient) { 
  }
  
  getAllSensors(zone_id:any): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(`${environment.ApiUrl+this.SensorsAllUrl+zone_id}`,{ headers: headers });
    
  }

  getAllData(sensor_id:any,inseretdAt:any = null): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    if (inseretdAt) {
      return this.http.post<any>(`${environment.ApiUrl+this.GetDataUrl+'?sensor_id='+sensor_id+'&inserted_at='+inseretdAt}`,{ headers: headers });
    }

    return this.http.post<any>(`${environment.ApiUrl+this.GetDataUrl+'?sensor_id='+sensor_id}`,{ headers: headers });
    
  }

  addSensor(data:any): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.post<any>(`${environment.ApiUrl+this.AddSensorUrl}`,data,{ headers: headers });
    
  }

  updateSensor(data:any): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

   
    return this.http.put<any>(`${environment.ApiUrl+this.UpdateSensorUrl+data.id}`,data,{ headers: headers });
    
  }

  deleteSensor(Sensor_id:number): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.delete<any>(`${environment.ApiUrl+this.DeleteSensorUrl+Sensor_id}`,{ headers: headers });
    
  }
}
