export type Sensor = {
  id?: string
  model?: string
  name?: string
  type?: SensorType
  status?: SensorStatus
  zoneId?: string|number
  location?: { lat: number; lng: number }
  description?: string
  sensor_type_id?: string
  camera_ids?: string[],
  data?:any
}

export type SensorType = { 
  id ?:String,
  name ?:String,
  unit ?:String,
  type ?:Types,
  max_value ?:String,
  min_value ?:String,
  alert_value ?:String,
  createdAt ?:Date,
}

export enum SensorStatus {
  ONLINE = "online",
  OFFLINE = "offline",
  UNDER_MAINTENANCE = "under_maintenance",
}

export enum Types {
  STRING = "STRING",
  INT = "INT",
  FLOAT = "FLOAT",
  BOOLEAN = "BOOLEAN"
}

export type SensorResponsePayload = {
  data?: Sensor[]
}