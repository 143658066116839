
import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-filter-input-search',
  templateUrl: './filter-input-search.component.html',
  styleUrls: ['./filter-input-search.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    NgIf,
    MatInputModule
]
})
export class FilterInputSearchComponent implements OnInit {
  @Input() tableColumn: any;
  @Output() filterInputSearched: EventEmitter<any> = new EventEmitter();
  filterControl = new FormControl('');
  isInputVisible = false;

  constructor() { }

  ngOnInit(): void {
    this.filterControl.valueChanges
      .pipe(debounceTime(800)) // 800ms delay
      .subscribe(value => {
        this.filterChanged(value);
      });
  }

  filterChanged(value: any): void {
    console.log('filterChanged', value);
    this.filterInputSearched.emit({ value, column: this.tableColumn });
  }

  toggleInputVisibility() {
    this.isInputVisible = !this.isInputVisible;
  }

}
