import { Component, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-video-viewer-dialog',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, CommonModule, MatIconModule],
  templateUrl: './video-viewer-dialog.component.html',
  styleUrls: ['./video-viewer-dialog.component.scss']
})
export class VideoViewerDialogComponent {
  @ViewChild('videoPlayer', { static: true }) videoPlayer!: ElementRef<HTMLVideoElement>;
  zoomLevel: number = 1;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  zoomIn(): void {
    this.zoomLevel += 0.1;
    this.videoPlayer.nativeElement.style.transform = `scale(${this.zoomLevel})`;
  }

  zoomOut(): void {
    if (this.zoomLevel > 0.1) {
      this.zoomLevel -= 0.1;
      this.videoPlayer.nativeElement.style.transform = `scale(${this.zoomLevel})`;
    }
  }

  downloadVideo(): void {
    const link = document.createElement('a');
    link.href = this.data.videoUrl;
    link.download = 'video.mp4';
    link.click();
  }
}
