import { NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Action } from '../../../models/table-columns.model';

@Component({
  selector: 'app-icon-action-cell',
  templateUrl: './icon-action-cell.component.html',
  styleUrls: ['./icon-action-cell.component.scss'],
  standalone:true,
  imports:[
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    NgTemplateOutlet
  ]
})
export class IconActionCellComponent {
  @Input() tableColumn: any;
  @Input() element: any;
  @Output() actionEmittedEvent: EventEmitter<any> = new EventEmitter();

  onAction(actionn: any): void {
      const act = {
          action: actionn,
          row: this.element,
      };
      this.actionEmittedEvent.emit(act);
  }


  showBtn(actionn: Action): any {
      return (
          !actionn.showConditionColumnName ||
          (actionn.showConditionColumnName &&
              this.element[actionn.showConditionColumnName] ===
              actionn.showConditionValue)
      );
  }

  containsActionsToHide(): boolean {
      const conditionShow = (action: any) => !this.showBtn(action);
      return !this.tableColumn.actions.every(conditionShow);
  }

  disableBtn(actionn: Action): any {
      return (
          actionn.disableConditionColumnName &&
          this.element[actionn.disableConditionColumnName] ===
          actionn.disableConditionValue
      );
  }

}
