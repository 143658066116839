<div >
  <div>
    @switch (element[tableColumn.dataKey]) {
      @case ('VALID') {
        <div>
          <span class="material-icons datatable-text-green"
            [matTooltip]="('validation.' + element[tableColumn.dataKey])">
            done
          </span>
        </div>
      }
      @case ('UNVALID') {
        <div>
          <span class="material-icons datatable-text-orange"
            [matTooltip]="('validation.' + element[tableColumn.dataKey])">
            block_flipped
          </span>
        </div>
      }
      @case ('ACTIVATED') {
        <div>
          <span class="material-icons datatable-text-green"
            [matTooltip]="('activated.' + element[tableColumn.dataKey])">
            done
          </span>
        </div>
      }
      @case ('DIACTIVATED') {
        <div>
          <span class="material-icons datatable-text-orange"
            [matTooltip]="('activated.' + element[tableColumn.dataKey])">
            block_flipped
          </span>
        </div>
      }
      @case ('DELETE') {
        <div>
          <span class="material-icons datatable-text-red"
            [matTooltip]="('activated.' + element[tableColumn.dataKey])">
            close
          </span>
        </div>
      }
      @case ('TODO') {
        <div>
          <span class="material-icons datatable-text-gray"
            [matTooltip]="('status.' + element[tableColumn.dataKey])">
            hourglass_empty
          </span>
        </div>
      }
      @case ('CANCELLED') {
        <div>
          <span class="material-icons datatable-text-orange"
            [matTooltip]="('status.' + element[tableColumn.dataKey])">
            block_flipped
          </span>
        </div>
      }
      @case ('DONE') {
        <div>
          <span class="material-icons datatable-text-green"
            [matTooltip]="('status.' + element[tableColumn.dataKey])">
            done
          </span>
        </div>
      }
      @case ('pdf') {
        <div>
          <svg fill="#000000" height="800px" width="800px" version="1.1" id="Capa_1"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"
            xml:space="preserve">
            <g>
              <g>
                <path
                            d="M47.987,21.938c-0.006-0.091-0.023-0.178-0.053-0.264c-0.011-0.032-0.019-0.063-0.033-0.094
        			c-0.048-0.104-0.109-0.202-0.193-0.285c-0.001-0.001-0.001-0.001-0.001-0.001L42,15.586V10c0-0.022-0.011-0.041-0.013-0.063
        			c-0.006-0.088-0.023-0.173-0.051-0.257c-0.011-0.032-0.019-0.063-0.034-0.094c-0.049-0.106-0.11-0.207-0.196-0.293l-9-9
        			c-0.086-0.086-0.187-0.148-0.294-0.197c-0.03-0.013-0.06-0.022-0.09-0.032c-0.086-0.03-0.174-0.047-0.264-0.053
        			C32.038,0.01,32.02,0,32,0H7C6.448,0,6,0.448,6,1v14.586l-5.707,5.707c0,0-0.001,0.001-0.002,0.002
        			c-0.084,0.084-0.144,0.182-0.192,0.285c-0.014,0.031-0.022,0.062-0.033,0.094c-0.03,0.086-0.048,0.173-0.053,0.264
        			C0.011,21.96,0,21.978,0,22v19c0,0.552,0.448,1,1,1h5v5c0,0.552,0.448,1,1,1h34c0.552,0,1-0.448,1-1v-5h5c0.552,0,1-0.448,1-1V22
        			C48,21.978,47.989,21.96,47.987,21.938z M44.586,21H42v-2.586L44.586,21z M38.586,9H33V3.414L38.586,9z M8,2h23v8
        			c0,0.552,0.448,1,1,1h8v5v5H8v-5V2z M6,18.414V21H3.414L6,18.414z M40,46H8v-4h32V46z M46,40H2V23h5h34h5V40z" />
                        <path d="M18.254,26.72c-0.323-0.277-0.688-0.473-1.097-0.586c-0.408-0.113-0.805-0.17-1.19-0.17h-3.332V38h2.006v-4.828h1.428
        			c0.419,0,0.827-0.074,1.224-0.221c0.397-0.147,0.748-0.374,1.054-0.68c0.306-0.306,0.552-0.688,0.74-1.148
        			c0.187-0.459,0.281-0.994,0.281-1.606c0-0.68-0.105-1.247-0.315-1.7C18.843,27.364,18.577,26.998,18.254,26.72z M16.971,31.005
        			c-0.306,0.334-0.697,0.501-1.173,0.501h-1.156v-3.825h1.156c0.476,0,0.867,0.147,1.173,0.442c0.306,0.295,0.459,0.765,0.459,1.411
        			C17.43,30.18,17.277,30.67,16.971,31.005z" />
                        <polygon points="30.723,38 32.78,38 32.78,32.832 35.857,32.832 35.857,31.081 32.764,31.081 32.764,27.8 36.112,27.8
        			36.112,25.964 30.723,25.964 		" />
                        <path d="M24.076,25.964H21.05V38h3.009c1.553,0,2.729-0.524,3.528-1.572c0.799-1.049,1.198-2.525,1.198-4.429
        			c0-1.904-0.399-3.386-1.198-4.446C26.788,26.494,25.618,25.964,24.076,25.964z M26.55,33.843c-0.13,0.528-0.315,0.967-0.552,1.318
        			c-0.238,0.351-0.521,0.615-0.85,0.79c-0.329,0.176-0.686,0.264-1.071,0.264h-0.969v-8.466h0.969c0.385,0,0.742,0.088,1.071,0.264
        			c0.329,0.175,0.612,0.439,0.85,0.79c0.238,0.351,0.422,0.793,0.552,1.326s0.196,1.156,0.196,1.87
        			C26.746,32.702,26.68,33.316,26.55,33.843z" />
              </g>
            </g>
          </svg>
        </div>
      }
      @case ('xsl') {
        <div>
          <svg fill="#000000" height="800px" width="800px" version="1.1" id="Capa_1"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 48 48"
            xml:space="preserve">
            <g>
              <g>
                <path
                            d="M47.988,21.94c-0.006-0.091-0.023-0.179-0.054-0.266c-0.011-0.032-0.019-0.063-0.033-0.094
            			c-0.048-0.103-0.108-0.199-0.191-0.283c-0.001-0.002-0.002-0.003-0.004-0.005L42,15.586V10c0-0.021-0.011-0.04-0.012-0.061
            			c-0.006-0.089-0.023-0.175-0.052-0.259c-0.011-0.032-0.019-0.062-0.033-0.093c-0.049-0.106-0.11-0.207-0.196-0.293l-9-9
            			c-0.085-0.085-0.186-0.146-0.291-0.195c-0.032-0.015-0.065-0.024-0.099-0.035c-0.081-0.027-0.164-0.044-0.249-0.05
            			C32.044,0.012,32.023,0,32,0H7C6.447,0,6,0.448,6,1v14.586l-5.706,5.706c-0.002,0.002-0.002,0.003-0.004,0.005
            			c-0.083,0.083-0.143,0.18-0.191,0.283c-0.014,0.03-0.022,0.062-0.033,0.094c-0.03,0.086-0.048,0.174-0.054,0.266
            			C0.011,21.961,0,21.979,0,22v19c0,0.552,0.447,1,1,1h5v5c0,0.552,0.447,1,1,1h34c0.553,0,1-0.448,1-1v-5h5c0.553,0,1-0.448,1-1V22
            			C48,21.979,47.989,21.961,47.988,21.94z M44.586,21H42v-2.586L44.586,21z M38.586,9H33V3.414L38.586,9z M8,2h23v8
            			c0,0.552,0.447,1,1,1h8v5v5H8v-5V2z M6,18.414V21H3.414L6,18.414z M40,46H8v-4h32V46z M46,40H2V23h5h34h5V40z" />
                <polygon
                  points="24.374,25.964 22.317,25.964 22.317,38 27.689,38 27.689,36.113 24.374,36.113 		" />
                        <path d="M31.021,27.885c0.147-0.136,0.312-0.235,0.493-0.298c0.181-0.062,0.368-0.093,0.561-0.093
            			c0.669,0,1.224,0.266,1.666,0.799l1.122-1.462c-0.329-0.385-0.734-0.677-1.215-0.876c-0.482-0.198-1.028-0.297-1.64-0.297
            			c-0.419,0-0.833,0.071-1.241,0.212c-0.408,0.142-0.774,0.36-1.097,0.655c-0.323,0.295-0.584,0.666-0.782,1.113
            			c-0.198,0.448-0.298,0.984-0.298,1.607c0,0.499,0.065,0.926,0.195,1.283c0.13,0.358,0.306,0.669,0.527,0.935
            			c0.221,0.267,0.476,0.496,0.765,0.689c0.289,0.193,0.598,0.368,0.927,0.527c0.521,0.261,0.952,0.544,1.292,0.85
            			c0.34,0.306,0.51,0.72,0.51,1.241c0,0.533-0.142,0.946-0.425,1.241c-0.283,0.295-0.64,0.442-1.071,0.442
            			c-0.385,0-0.762-0.091-1.131-0.272s-0.683-0.431-0.944-0.748l-1.105,1.496c0.34,0.397,0.793,0.725,1.36,0.986
            			c0.567,0.261,1.184,0.391,1.853,0.391c0.465,0,0.907-0.079,1.326-0.238c0.419-0.159,0.785-0.394,1.097-0.706
            			c0.312-0.311,0.561-0.694,0.748-1.147s0.28-0.975,0.28-1.564c0-0.51-0.079-0.952-0.238-1.326
            			c-0.159-0.374-0.363-0.697-0.612-0.969c-0.249-0.272-0.527-0.504-0.833-0.697c-0.306-0.193-0.606-0.363-0.901-0.51
            			c-0.499-0.249-0.901-0.513-1.207-0.791c-0.306-0.277-0.459-0.671-0.459-1.181c0-0.295,0.042-0.55,0.128-0.765
            			C30.757,28.197,30.874,28.021,31.021,27.885z" />
                        <polygon points="20.719,25.964 18.628,25.964 16.86,30.112 15.075,25.964 12.95,25.964 15.67,31.659 12.661,38 14.786,38
            			16.809,33.393 18.815,38 21.008,38 17.982,31.659 		" />
              </g>
            </g>
          </svg>
        </div>
      }
    }
  </div>
</div>
