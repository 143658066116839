import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sensor } from '../../core/types/Sensor';
import { CardService } from '../../services/card/card.service';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-add-card',
  standalone: true,
  imports: [    
    ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
  MatIconModule],
  templateUrl: './add-card.component.html',
  styleUrl: './add-card.component.scss'
})
export class AddCardComponent {


  form: FormGroup;
  iscreate:boolean = true;

  configSnakebar = {
    duration: 30000,
    panelClass: ['warning-snackbar'],
    verticalPosition: 'top'
  }

  constructor(
    private _snackBar: MatSnackBar,
    private cardService:CardService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data:   Sensor 
  ) {


    this.form = this.formBuilder.group({
      id: ['', Validators.nullValidator],
      name: ['', Validators.required],
      lastOnlineAt: ['', Validators.nullValidator],
      startedAt: ['', Validators.nullValidator],
      zoneId: ['', Validators.required],
      model: ['', Validators.nullValidator],
      location_lng: [0, Validators.nullValidator],
      location_lat: [0, Validators.nullValidator],
      adjustableCoverage: [false, Validators.nullValidator],
      description: ['', Validators.nullValidator],
      isOnline: [true, Validators.nullValidator],
      modelName: ['', Validators.nullValidator],
      status: ['', Validators.nullValidator],
      thumbnailUrl: ['', Validators.nullValidator],
    });

  }

  ngOnInit(): void {

    if (this.data.name) {
      this.form.patchValue(this.data);
      this.iscreate = false;
    }else{
      this.form.patchValue({'zoneId':this.data.zoneId});
    }
    
  }
  
  
  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {

    if (this.iscreate) {
      if (this.form.valid) {
        this.cardService.addCard(this.form.value).subscribe(
          (response) => {
            this.openSnackBar(response.message,"close");
            this.dialogRef.close(true);
          }
        );
      }
    } else {
      if (this.form.valid) {
        this.cardService.updateCard(this.form.value).subscribe(
          (response) => {
            this.openSnackBar(response.message,"close");
            this.dialogRef.close(true);
          }
        );
      }
    }
   
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

}
