<div class="datatable">
  <div>

    <!-- Table -->
    <mat-table [dataSource]="tableDataSource" matSort (matSortChange)="sortTable($event)" multiTemplateDataRows>
      @for (tableColumn of tableColumns; track tableColumn) {
        <ng-container [matColumnDef]="tableColumn.name">
          <!-- if sortable column header -->
          @if (tableColumn.isSortable) {
                    <mat-header-cell class="truncate" *matHeaderCellDef [mat-sort-header]="tableColumn.name" [ngStyle]="{'min-width': (tableColumn.width), 'max-width': (tableColumn.width),
                    'width': (tableColumn.width), 'justify-content': tableColumn.position}"
              [matTooltip]="tableColumn.tooltip ? (tableColumn.tooltip) : (tableColumn.name)">
              {{(tableColumn.name)}}
            </mat-header-cell>
          } @else {
             <!-- else not sortable -->
            <mat-header-cell *matHeaderCellDef
                        [ngStyle]="{'min-width': (tableColumn.minWidth ?? tableColumn.width),
                    'max-width': (tableColumn.maxWidth ?? tableColumn.width), 'width': (tableColumn.width), 'justify-content': tableColumn.position}"
              class="truncate">
              <div class="truncate">
                @switch (tableColumn.headerType) {
                  @case ('simple') {
                    <div class="truncate"
                      [matTooltip]="tableColumn.tooltip ? (tableColumn.tooltip) : (tableColumn.name)">
                      {{(tableColumn.name)}}
                    </div>
                  }
                  @case ('filter') {
                    <div>
                      <app-filter-header [tableColumn]="tableColumn"
                        (filterChangedEvent)="filterChanged(tableColumn, $event)">
                      </app-filter-header>
                    </div>
                  }

                  @case ('filter-input') {
                    <div>
                      <app-filter-input-search [tableColumn]="tableColumn"
                        (filterInputSearched)="filterChanged(tableColumn, $event)">
                      </app-filter-input-search>
                    </div>
                  }

                }
              </div>
            </mat-header-cell>
          }

          <!-- column data -->
          <mat-cell *matCellDef="let element; let i = index;"
                    [ngStyle]="{'min-width': (tableColumn.minWidth ?? tableColumn.width),
                    'max-width': (tableColumn.maxWidth ?? tableColumn.width), 'width': (tableColumn.width), 'justify-content': tableColumn.position}" class="truncate">
            <div class="truncate"
              [ngStyle]="{'width': tableColumn.cellContainerWidth}">
              @switch (tableColumn.cellType) {
                @case ('text') {
                  <div [ngClass]="['truncate',tableColumn?.class ?? '']">
                    <span tabindex="0" (click)="onRowAction({tableColumn,row:element,mouseEvent:$event})"
                      class="text-overflow font-medium"
                      [matTooltip]="tooltipConditions(element,tableColumn)">
                      {{tableColumn.dataKey && (element[tableColumn.dataKey]) ?
                      (element[tableColumn.dataKey]) :
                      '/'}}
                    </span>
                  </div>
                }
                @case ('date') {
                  <div [ngClass]="['truncate',tableColumn?.class ?? '']">
                    <span tabindex="0" (click)="onRowAction({tableColumn,row:element,mouseEvent:$event})"
                      class="text-overflow font-medium"
                      [matTooltip]="tooltipConditions(element,tableColumn)">
                      {{tableColumn.dataKey && (element[tableColumn.dataKey]) ?
                      (element[tableColumn.dataKey] | date:'mm/dd/yyyy - HH:mm:ss') :
                      '/'}}
                    </span>
                  </div>
                }

                @case ('icon-action') {
                  <div>
                    <app-icon-action-cell [tableColumn]="tableColumn" [element]="element"
                    (actionEmittedEvent)="onRowAction({objectToSend:$event})"></app-icon-action-cell>
                  </div>
                }
                @case ('action') {
                  <div>
                    <app-action-cell  [tableColumn]="tableColumn" [element]="element"
                    (actionEmittedEvent)="onRowAction({objectToSend:$event})"></app-action-cell>
                  </div>
                }
                @case ('status') {
                  <div>
                    <app-status-cell (actionEmittedEvent)="onRowAction({objectToSend:$event})"
                    [tableColumn]="tableColumn" [element]="element"></app-status-cell>
                  </div>
                }
                @case ('url') {
                  <a href="{{element.url}}" target="_blank" [matTooltip]="tooltipConditions(element,tableColumn)"
                   class="inline-flex items-center justify-center w-7 h-7 rounded-full bg-primary-500 text-white hover:bg-primary-800">
                    <mat-icon>play_circle_outline</mat-icon>
                  </a>
                }
                @case ('imageViewer') {
                  <span class="cursor-pointer" [matTooltip]="tooltipConditions(element,tableColumn)"
                  (click)="onRowAction({objectToSend:{action: 'viewImage',row: element,}})"
                  (keydown)="onRowAction({objectToSend:$event})"
                                     class="inline-flex items-center justify-center w-7 h-7 rounded-full bg-primary-500 text-white hover:bg-primary-800">
                    <mat-icon>camera_enhance</mat-icon>
                  </span>
                }
              }
            </div>
          </mat-cell>
        </ng-container>
      }


      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <mat-row  class="cursor-pointer"
        *matRowDef="let row; columns: displayedColumns; let i = index" (click)="rowAction('rowClick',row)" (keydown)="rowAction('rowClick', row)">
      </mat-row>


    </mat-table>

    @if (totalItems > 0) {
      <mat-paginator [length]="totalItems" [pageSizeOptions]="paginationSizes" [hidePageSize]="false"
        [showFirstLastButtons]="true" (page)="pageChanged($event)" [pageSize]="defaultPageSize"
      ></mat-paginator>
    }
  </div>
</div>
