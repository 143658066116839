import { createDateSimpleColumn, createFilterColumn, createSimpleColumn } from "../../shared/config-table";
import { TableColumn } from "../../shared/models/table-columns.model";

export const displayedColumns: TableColumn[] = [
  createSimpleColumn('ID', 'id',false),
  createSimpleColumn('Message','message',false),
  createFilterColumn('Type', 'alert_type',[{value:'high',label:'High'},{value:'low',label:'Low'}],true),
  createSimpleColumn('Value','value',true),
  createSimpleColumn('Threshold','threshold',true),
  createFilterColumn('State', 'status',[{value:'Active',label:'active'},{value:'Desactivated',label:'desactivated'}],true),
  createDateSimpleColumn('CreatedAt', 'created_at', true),

  {
    name: 'Actions',
    position: 'flex-end',
    isSortable: false,
    headerType: 'simple',
    cellType: 'action',
    actions: [
    {
      label: 'Deactivate',
      materialIconName: 'remove_red_eye',
      action: 'deactivate'
    }]
  },
];
