import { Component,ChangeDetectionStrategy,Inject,inject, signal } from '@angular/core';
import {  MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardActions, MatCardContent, MatCardFooter, MatCardImage } from '@angular/material/card';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SafePipe } from '../../shared/pipes/safe.pipe';
import { CommonModule } from '@angular/common';
import { MatChip, MatChipSet, MatChipsModule } from '@angular/material/chips';
import {  MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { SensorTypesService } from '../../services/sensor-types/sensor_types.service';
import { MatMenuModule } from '@angular/material/menu';
import { AddSensorTypeComponent } from '../../components/add-sensor-type/add-sensor-type.component';
import { ThemeService } from '../../services/theme.service';
import { SharedTableComponent } from '../../shared/components/shared-table/shared-table.component';
import { Action, TableColumn } from '../../shared/models/table-columns.model';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-sensor-types',
  standalone: true,
  imports: [MatIconModule, MatPaginatorModule, RouterLink,MatChip
    ,MatChipsModule, MatChipSet, CommonModule,
     MatCard,MatCardContent,MatCardActions,MatCardFooter,MatCardImage,FormsModule,
     MatGridList,MatGridTile,MatButtonModule,SafePipe,MatMenuModule,SharedTableComponent],
  templateUrl: './sensor-types.component.html',
  styleUrl: './sensor-types.component.scss'
})

export class SensorTypesComponent {

  IsDarkMode:boolean = false;
  searchText: string = '';
  dataSource :any
  sensorTypes :any
  recordings:any = null;
  readonly deleteDialog = inject(MatDialog);
  readonly dialog = inject(MatDialog);

  constructor(private darkMode:ThemeService,private sensortypesService:SensorTypesService,private _snackBar: MatSnackBar){

  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

    this.darkMode.isDarkMode$.subscribe(value =>{
      this.IsDarkMode = value;
    });

    this.loadingTable.set(true);
    setTimeout(() => {
      this.getSensortypes();
    }, 500);
  }

  actions : Action[] = [
    {
        label: 'Update',
        materialIconName: 'edit',
        action: 'update'
    },
    {
      label: 'Delete',
      materialIconName: 'remove',
      action: 'delete'
  },
  {
    label: 'Disable alerts',
    materialIconName: 'notifications_off',
    action: 'deactivate'
}
  ];

  pageSizeOptions = [10, 20, 50];

  displayedColumns: TableColumn[] = [
    {
        name: 'Name',
        dataKey: 'name',
        dataKeyDtoSort: 'name',
        cellType: 'text',
        headerType: 'simple',
    },
    {
      name: 'Unit',
      dataKey: 'unit',
      dataKeyDtoSort: 'unit',
      cellType: 'text',
      headerType: 'simple',
  },

    {
        name: 'MinValue',
        dataKey: 'min_value',
        dataKeyDtoSort: 'min_value',
        cellType: 'text',
        headerType: 'simple',
    },
    {
      name: 'MaxValue',
      dataKey: 'max_value',
      dataKeyDtoSort: 'max_value',
      cellType: 'text',
      headerType: 'simple',
  },

    {
      name: 'Created At',
      dataKey: 'created_at',
      dataKeyDtoSort: 'created_at',
      cellType: 'date',
      headerType: 'simple',
    },

    {
        name: 'actions',
        position: 'flex-end',
        isSortable: false,
        headerType: 'simple',
        cellType: 'action',
        actions: this.actions
    },
  ];

  totalItems = 0;
  loadingTable = signal(false);



  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  getSensortypes(){

    this.sensortypesService.getAllSensorTypes().subscribe(
      (response) => {
        if (response) {
          this.sensorTypes = response;
          this.dataSource = response;
          this.loadingTable.set(false);
          this.totalItems= 50;
        }
      }
    );
  }


  openCreateDialog(): void {
     this.dialog.open(AddSensorTypeComponent, {
      width: '75vw',
      panelClass: this.IsDarkMode ? 'dark' : ''
    }).afterClosed().subscribe((result) => {
        this.getSensortypes();
    });
  }

  openUpdateDialog(data:any): void {
    this.dialog.open(AddSensorTypeComponent, {
     width: '75vw',
     data:data,
     panelClass: this.IsDarkMode ? 'dark' : ''
   }).afterClosed().subscribe((result) => {
       this.getSensortypes();
   });
 }


  openDeleteDialog(sensorType_id:number,enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.deleteDialog.open(DialogDeleteSensorType, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { sensorType_id: sensorType_id }
    }).afterClosed().subscribe((result) => {
        this.getSensortypes();
    });

  }

  changedPage(event: PageEvent) {
    console.log('event ',event);

  }

  changedSort(sort: any) {
    console.log('sort ',sort)

  }

  rowSelect(event: any) {
    console.log('event row select ',event)
  }

  actionOnTable(event: { action: string, row: any }) {
    console.log('event action on table',event);

    switch (event.action) {
      case 'update':
        this.openUpdateDialog(event.row);
        break;
      case 'delete':
        this.openDeleteDialog(event.row.id,'300ms','300ms');
        break;
      case 'deactivate':
        this.openUpdateDialog(event.row);
        break;
      default:
        break;
    }
  }

  onChangeFilter(event:{column:string,value: string[]}) {
    console.log('event ',event)
  }
}

@Component({
  selector: 'delete-sensortype-dialog',
  templateUrl: '../../shared/templates/delete-sensortype-dialog.html',
  standalone: true,
  imports: [MatButtonModule,MatDialogModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogDeleteSensorType {
  readonly dialogRef = inject(MatDialogRef<DialogDeleteSensorType>);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { sensorType_id: number }
  ,private sensorTypeService:SensorTypesService,private _snackBar: MatSnackBar){

  }

  deleteSensorType(sensorType_id:number){
    this.sensorTypeService.deleteSensorType(sensorType_id).subscribe(
      (response) => {
        if(response.message){
          this.openSnackBar(response.message,"close")
          this.dialogRef.close(true);
        }
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

}
