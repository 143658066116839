import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { FilterHeaderComponent } from './filter-header/filter-header.component';
import { ActionCellComponent } from './action-cell/action-cell.component';
import { IconActionCellComponent } from './icon-action-cell/icon-action-cell.component';
import { StatusCellComponent } from './status-cell/status-cell.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TableColumn } from '../../models/table-columns.model';
import { FilterInputSearchComponent } from './filter-header-input-search/filter-input-search.component';
import { MatIcon } from '@angular/material/icon';
@Component({
    selector: 'app-shared-table',
    templateUrl: './shared-table.component.html',
    styleUrls: ['./shared-table.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatTableModule,
        MatTooltipModule,
        MatSortModule,
        MatPaginatorModule,
        FilterHeaderComponent,
        ActionCellComponent,
        IconActionCellComponent,
        StatusCellComponent,
        FilterInputSearchComponent,
        MatIcon,
    ]
})
export class SharedTableComponent implements OnInit {
    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.sort = ms;
        this.setDataSourceAttributes();
    }

    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        this.setDataSourceAttributes();
    }

    @Input() tableColumns!: TableColumn[];
    @Input() paginationSizes: number[] = [10, 20, 50];
    @Input() defaultPageSize = this.paginationSizes[0];
    @Input() totalItems!: number;
    @Input() page!: number;
    @Input() rowClasses?: string[];

    @Output() sortEvent: EventEmitter<Sort> = new EventEmitter();
    @Output() actionRowEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() pageChangedEvent: EventEmitter<any> = new EventEmitter();
    @Output() filtersChangedEvent: EventEmitter<any> = new EventEmitter();
    @Output() actionEmittedEvent: EventEmitter<any> = new EventEmitter();

    @Input() set tableData(data: any[]) {
        this.setTableDataSource(data);
    }


    private paginator!: MatPaginator;
    private sort: MatSort = new MatSort;
    public tableDataSource = new MatTableDataSource<Element>([]);
    public displayedColumns: string[] = [];

    constructor(
    ) {
    }


    ngOnInit(): void {
        const columnNames = this.tableColumns.map((tableColumn: TableColumn) => tableColumn.name);
        this.displayedColumns = columnNames;
    }



    /**
     * set Text for paginator
     */
    initIntl(): void {

                this.paginator._intl.itemsPerPageLabel = ('Elements par page');
                this.paginator._intl.nextPageLabel = ('Page suivante');
                this.paginator._intl.previousPageLabel = ('Page précédente');
                this.paginator._intl.lastPageLabel = ('Dernière page');
                this.paginator._intl.firstPageLabel = ('Première page');
                this.paginator._intl.getRangeLabel = (page, pageSize, length): string => {
                    if (length === 0 || pageSize === 0) {
                        return '0 / ' + length;
                    }
                    length = Math.max(length, 0);
                    const startIndex = page * pageSize;
                    // If the start index exceeds the list length, do not try and fix the end index to the end.
                    const endIndex = startIndex < length ?
                        Math.min(startIndex + pageSize, length) :
                        startIndex + pageSize;
                    return startIndex + 1 + ' - ' + endIndex + ' / ' + length;
                };

    }

    /**
     * set data source attributes
     */
    setDataSourceAttributes(): void {
        if (this.sort && this.paginator) {
            this.tableDataSource.sort = this.sort;
            this.initIntl();
        }
    }

    setTableDataSource(data: any[]): void {
        this.tableDataSource = new MatTableDataSource<any>(data);
    }

    /**
     * sort table
     *
     * @param sortParameters sortParameters
     */
    sortTable(sortParameters: Sort): void {
        // defining name of data property, to sort by, instead of column name
        const column = this.tableColumns.find(column => column.name === sortParameters.active);
        sortParameters.active = column?.dataKeyDtoSort ?? sortParameters.active;
        if (this.totalItems > 0) {
            this.sortEvent.emit(sortParameters);
        }

    }

    /**
     * action table
     *
     * @param action action
     * @param selectedRow selectedRow
     */
    rowAction(action: any, selectedRow: any): void {
        const act = {
            actionType: action,
            row: selectedRow
        };
        this.actionRowEvent.emit(act);
    }

    /**
     * page changed
     *
     * @param event event on page changed
     */
    pageChanged(event: { pageIndex: any; pageSize: any; }): void {
        const page = {
            pageIndex: event.pageIndex,
            pageSize: event.pageSize,
        };
        this.pageChangedEvent.emit(
            page
        );
    }

    /**
     * on change filters
     *
     * @param value filtersValue
     */
    filterChanged(column: any, value: any): void {
        if (this.paginator) {
            this.paginator.firstPage();
        }
        const filter = {
            column: column.dataKey,
            value
        };
        this.filtersChangedEvent.emit(filter);
    }


    /**
     * Action emitted
     *
     * @param action action
     */
    onRowAction({ objectToSend, tableColumn, row, mouseEvent }: { objectToSend?: any, tableColumn?: TableColumn, row?: any, mouseEvent?: MouseEvent }) {

        if (tableColumn?.isClickable) {
            this.mousePropagationStopper({mouseEvent});
            this.mouseDefaultActionStopper({mouseEvent});
        }
        this.actionEmittedEvent.emit(objectToSend ?? {
            action: tableColumn?.clickAction,
            row: row,
        });
    }

    onTestAct(event:MouseEvent){
        if(event.type ==='click'){
            event.stopPropagation()
        }
    }



  /**
   * mat tooltip conditions
   */
    tooltipConditions(row: any, tableColumn: TableColumn): string {
        const tooltipLabel = tableColumn.tooltip  ? tableColumn.tooltip : tableColumn.dataKey;
        return row[tooltipLabel as keyof typeof row];
    }

    /** mouse propagation stop */
    mousePropagationStopper({mouseEvent}:{mouseEvent?:MouseEvent}):void{
        if(mouseEvent){
            mouseEvent.stopPropagation();
        }
    }

    /** mouse default action stop */
    mouseDefaultActionStopper({mouseEvent}:{mouseEvent?:MouseEvent}):void{
        if(mouseEvent){
            mouseEvent.preventDefault();
        }
    }
}
