import { Component,ChangeDetectionStrategy,Inject,inject, signal } from '@angular/core';
import {  MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardActions, MatCardContent, MatCardFooter, MatCardImage } from '@angular/material/card';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SafePipe } from '../../shared/pipes/safe.pipe';
import { CommonModule } from '@angular/common';
import { MatChip, MatChipSet, MatChipsModule } from '@angular/material/chips';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { RecordingService } from '../../services/recording/recording.service';
import { CreateRecordingComponent } from '../../components/create-recording/create-recording.component';
import { MatMenuModule } from '@angular/material/menu';
import { ThemeService } from '../../services/theme.service';
import { Action, TableColumn } from '../../shared/models/table-columns.model';
import { SharedTableComponent } from '../../shared/components/shared-table/shared-table.component';
import { PageEvent } from '@angular/material/paginator';
import { FormsModule } from '@angular/forms';
// import { Action } from 'rxjs/internal/scheduler/Action';

export interface VideoRecording {
  dateFrom: Date;
  timeFrom: string;
  dateTo: Date;
  timeTo: string;
  duration: string;
  description: string;
  camRef: string;
  project: string;
  zone: string;
  createdAt: Date;
  url: string;
}

const VIDEO_RECORDINGS: VideoRecording[] = [
  {
    dateFrom: new Date('2024-08-01'),
    timeFrom: '10:00:00',
    dateTo: new Date('2024-08-01'),
    timeTo: '11:00:00',
    duration: '1h',
    description: 'Meeting with team',
    camRef: 'Cam001',
    project: 'Project Alpha',
    zone: 'Conference Room',
    createdAt: new Date('2024-08-01T11:05:00'),
    url: 'https://example.com/videos/meeting_with_team.mp4',
  },
  {
    dateFrom: new Date('2024-08-02'),
    timeFrom: '14:00:00',
    dateTo: new Date('2024-08-02'),
    timeTo: '15:30:00',
    duration: '1h 30m',
    description: 'Security check',
    camRef: 'Cam002',
    project: 'Project Beta',
    zone: 'Entrance',
    createdAt: new Date('2024-08-02T15:35:00'),
    url: 'https://example.com/videos/security_check.mp4',
  },
  // Add more video recordings as needed
];



@Component({
  selector: 'app-recordings',
  standalone: true,
  imports: [
    MatIconModule, RouterLink,MatChip,MatChipsModule, MatChipSet, CommonModule,
     MatCard,MatCardContent,MatCardActions,MatCardFooter,MatCardImage,
     MatGridList,MatGridTile,MatButtonModule,SafePipe,MatMenuModule,SharedTableComponent,FormsModule],
  templateUrl: './recordings.component.html',
  styleUrl: './recordings.component.scss'
})
export class RecordingsComponent {
  searchText: string = '';

  IsDarkMode:boolean = false;
  dataSource :any
  recordings:any = null;
  readonly deleteDialog = inject(MatDialog);
  readonly dialog = inject(MatDialog);





  actions : Action[] = [
    {
        label: 'deactivate',
        materialIconName: 'remove_red_eye',
        action: 'deactivate'
    }
  ];

  pageSizeOptions = [10, 20, 50];

  displayedColumns: TableColumn[] = [
    {
        name: 'Date From',
        dataKey: 'dateFrom',
        dataKeyDtoSort: 'dateFrom',
        cellType: 'date',
        headerType: 'simple',
    },
    {
      name: 'Time From',
      dataKey: 'timeFrom',
      dataKeyDtoSort: 'timeFrom',
      cellType: 'text',
      headerType: 'simple',
  },
  {
    name: 'Date To',
    dataKey: 'dateTo',
    dataKeyDtoSort: 'dateTo',
    cellType: 'date',
    headerType: 'simple',
  },
    {
        name: 'Time To',
        dataKey: 'timeTo',
        dataKeyDtoSort: 'timeTo',
        cellType: 'text',
        headerType: 'simple',
    },
    {
      name: 'Duration',
      dataKey: 'duration',
      dataKeyDtoSort: 'duration',
      cellType: 'text',
      headerType: 'simple',
  },
  {
    name: 'Description',
    dataKey: 'description',
    dataKeyDtoSort: 'description',
    cellType: 'text',
    headerType: 'simple',
},
{
  name: 'Camera Ref',
  dataKey: 'camRef',
  dataKeyDtoSort: 'camRef',
  cellType: 'text',
  headerType: 'simple',
},
{
  name: 'Project',
  dataKey: 'project',
  dataKeyDtoSort: 'project',
  cellType: 'text',
  headerType: 'simple',
},
{
  name: 'Zone',
  dataKey: 'zone',
  dataKeyDtoSort: 'zone',
  cellType: 'text',
  headerType: 'simple',
},


    {
      name: 'Created At',
      dataKey: 'createdAt',
      dataKeyDtoSort: 'createdAt',
      cellType: 'date',
      headerType: 'simple',
    },
    {
      name: 'URL',
      dataKey: 'url',
      dataKeyDtoSort: 'url',
      cellType: 'text',
      headerType: 'simple',
    },

    {
        name: 'actions',
        position: 'flex-end',
        isSortable: false,
        headerType: 'simple',
        cellType: 'action',
        actions: this.actions
    },
  ];

  totalItems = 12;
  loadingTable = signal(false);

  constructor(private darkMode:ThemeService,private recordingservice:RecordingService,private _snackBar: MatSnackBar){

  }
  ngOnInit() {
    this.darkMode.isDarkMode$.subscribe(value =>{
      this.IsDarkMode = value;
    });
    this.loadingTable.set(true);
    setTimeout(() => {
      this.getRecordings();
    }, 500);
    this.dataSource = VIDEO_RECORDINGS;
    this.loadingTable.set(false);

  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  getRecordings() {

    // this.recordingservice.getAllRecordings().subscribe(
    //   (response) => {

    //     if (response.data) {
    //       this.recordings = response.data[0];
    //     }else{
    //       this.recordings = [];
    //     }
    //   }
    // );
  }

  openCreateDialog(): void {
     this.dialog.open(CreateRecordingComponent, {
      width: '75vw',
      panelClass: this.IsDarkMode ? 'dark' : ''
    }).afterClosed().subscribe((result) => {
        this.getRecordings();
    });
  }


  openDeleteDialog(recording_id:number,enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.deleteDialog.open(DialogDeleteRecording, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { recording_id: recording_id }
    }).afterClosed().subscribe((result) => {
        this.getRecordings();
    });

  }

  changedPage(event: PageEvent) {
    console.log('event ',event);

  }

  changedSort(sort: any) {
    console.log('sort ',sort)

  }

  rowSelect(event: any) {
    console.log('event row select ',event)
  }

  actionOnTable(event: { action: string, row: any }) {
    console.log('event action on table',event)
  }

  onChangeFilter(event:{column:string,value: string[]}) {
    console.log('event ',event)
  }
}

@Component({
  selector: 'delete-recording-dialog',
  templateUrl: '../../shared/templates/delete-recording-dialog.html',
  standalone: true,
  imports: [MatButtonModule,MatDialogModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogDeleteRecording {
  readonly dialogRef = inject(MatDialogRef<DialogDeleteRecording>);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { recording_id: number }
  ,private recordingservice:RecordingService,private _snackBar: MatSnackBar){

  }

  deleteRecording(recording_id:number){
    this.recordingservice.deleteRecording(recording_id).subscribe(
      (response) => {
        if(response.message){
          this.openSnackBar(response.message,"close")
          this.dialogRef.close(true);
        }
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

}
