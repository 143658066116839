import { Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProjectsComponent } from './pages/projects/projects.component';
import { SensorsComponent } from './pages/sensors/sensors.component';
import { LoginComponent } from './pages/login/login.component';
import { ConnectedGuard } from './core/guards/connected.guard';
import { DisconnectedGuard } from './core/guards/disconnected.guard';
import { ZonesComponent } from './pages/zones/zones.component';
import { CamerasComponent } from './pages/cameras/cameras.component';
import { EmailComponent } from './pages/email/email.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { RecordingsComponent } from './pages/recordings/recordings.component';
import { CameraswatchComponent } from './pages/cameraswatch/cameraswatch.component';
import { CardsComponent } from './pages/cards/cards.component';
import { SensorTypesComponent } from './pages/sensor-types/sensor-types.component';
import { DashboardDetailsComponent } from './pages/dashboard/details/dashboard-details.component';
import { DetailsSensorComponent } from './pages/dashboard/details-sensor/details-sensor.component';

export const routes: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo:'dashboard'

    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        pathMatch: 'full',
        canActivate:[ConnectedGuard]
    },
    {
        path: 'dashboard/:project_id',
        component: DashboardDetailsComponent,
        pathMatch: 'full',
        canActivate:[ConnectedGuard]
    },
    {
        path: 'dashboard-zones',
        component: DetailsSensorComponent,
        pathMatch: 'full',
        canActivate:[ConnectedGuard]
    },
    {
        path: 'projects',
        component: ProjectsComponent,
        pathMatch: 'full',
        canActivate:[ConnectedGuard]
    },
    {
        path: 'zones/:project_id/:project_name',
        component: ZonesComponent,
        pathMatch: 'full',
        canActivate:[ConnectedGuard]
    },
    {
        path: 'cards/:zone_id/:zone_name',
        component: CardsComponent,
        pathMatch: 'full',
        canActivate:[ConnectedGuard]
    },
    {
        path: 'sensors/:card_id/:zone_id/:card_name/:zone_name',
        component: SensorsComponent,
        pathMatch: 'full',
        canActivate:[ConnectedGuard]
    },
    {
        path: 'cameras/:zone_id/:zone_name',
        component: CamerasComponent,
        pathMatch: 'full',
        canActivate:[ConnectedGuard]
    }, 
    {
        path: 'cameraswatch/:zone_id/:zone_name',
        component: CameraswatchComponent,
        pathMatch: 'full',
        canActivate:[ConnectedGuard]
    },
    {
        path: 'email',
        component: EmailComponent,
        pathMatch: 'full',
        canActivate:[ConnectedGuard]
    },
    {
        path: 'alerts',
        component: NotificationsComponent,
        pathMatch: 'full',
        canActivate:[ConnectedGuard]
    },
    {
        path: 'alerts',
        component: SensorsComponent,
        pathMatch: 'full',
        canActivate:[ConnectedGuard]
    },
    {
        path: 'recordings',
        component: RecordingsComponent,
        pathMatch: 'full',
        canActivate:[ConnectedGuard]
    },
    {
        path: 'sensortypes',
        component: SensorTypesComponent,
        pathMatch: 'full',
        canActivate:[ConnectedGuard]
    },
    {
        path: 'login',
        component: LoginComponent,
        pathMatch: 'full',
        canActivate:[DisconnectedGuard]
    }
];