import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { AuthService } from '../../services/auth/auth-service.service';

export const authInterceptor: HttpInterceptorFn = (req, next) => {

  const router = inject(Router);
  const auth = inject(AuthService);

  const token = localStorage.getItem('CamAppToken');
  const cloneReq = req.clone({
    setHeaders: {
      Authorization : 'Bearer '+token
    }
  })


  return next(cloneReq).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.status === 401) {//get new token when expired token

        auth.Disconnected();
        router.navigate(['/login']);

      }
      return throwError(error.message);
    })
  );

};
