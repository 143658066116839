<div class="m-5 mt-5">
  <div>
    <div class="w-full">
      <div class="flex justify-center mb-5">
        <div class="inline-flex space-x-2">
          <button *ngFor="let filter of ['lasthour', 'hourly', 'daily', 'weekly', 'monthly', 'yearly', 'all']"
                  [ngClass]="{'bg-primary-500 text-white': currentFilter === filter}"
                  (click)="currentFilter = filter; updateCharts()"
                  class="px-4 py-2 border rounded hover:bg-primary-300 transition">
            {{ filter | titlecase }}
          </button>
        </div>
      </div>
    </div>

    <ng-container *ngIf="isActive; else shimmer">
      <ng-container *ngIf="zones && zones?.length > 0; else empty">
        <div class="grid grid-cols-2 gap-4 my-3" >
          <div  *ngFor="let chartOptions of chartOptionsList; let i = index">
          <div class="relative flex flex-col items-center ">
            <button class="absolute w-[40px] h-[40px] top-2 right-2 bg-primary-500 text-white p-2 rounded-full hover:bg-primary-600 transition" style="z-index: 99999;" [routerLink]="['/dashboard-zones']" [state]="{ data: zones[i].sensors }">
              <mat-icon>query_stats</mat-icon>
            </button>
            <!-- <span class="mt-1 text-gray-700">See Separated</span> -->
            <highcharts-chart class="w-full h-[400px]"
                              [Highcharts]="Highcharts"
                              [options]="chartOptions">
            </highcharts-chart>
          </div>
        </div>
      </div>
      </ng-container>
    </ng-container>

    <!-- Shimmer Loading Template -->
    <ng-template #shimmer>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4 my-3">
        <div *ngFor="let n of [1,2,3,4,5,6,7,8]" class="rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 animate-pulse bg-gradient-to-r from-gray-300 via-gray-200 to-gray-300 ">
          <div class="w-full h-[400px] rounded relative overflow-hidden">
            <div class="absolute inset-0"></div>
          </div>
        </div>
      </div>
    </ng-template>


  <ng-template #empty>
    <div class="flex flex-col items-center justify-center p-6  mt-5 ">
      <!-- SVG Image -->

      <mat-icon class="mat-18 text-[200px] w-full h-full text-center m-auto">folder</mat-icon>
      <!-- Text -->
      <h3 class="text-lg font-semibold text-gray-900 dark:text-white mb-2">No Zones Yet</h3>
      <p class="text-gray-500 dark:text-gray-400 text-center">Create a new zone to get started.</p>
    </div>

  </ng-template>
  </div>
</div>
