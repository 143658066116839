import { type Zone } from "./Zone"

export type Project = {
  id: string | number
  hash: string
  name: string
  hasAlerts: boolean
  status?: ProjectStatus
  insertedAt?: string
  startedAt?: string
  zones: Array<Zone>
  zonesCount: number
  camerasCount?: number
  sensorsCount?: number
  cardesCount?: number
  
}

export enum ProjectStatus {
  ToStart = "To Start",
  InProgress = "In Progress",
  Completed = "Completed",
}

export type ProjectResponsePayload = {
  data?: Project[]
}

