import { Component, EventEmitter, HostBinding, Inject, Input, Output, effect, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeService } from '../../../services/theme.service';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { AuthService } from '../../../services/auth/auth-service.service';
import { Router, RouterModule } from '@angular/router';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { Subscription } from 'rxjs';
import { AlertsService } from '../../../services/alerts/alerts.service';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    standalone: true,
    imports: [RouterModule, MatDividerModule, MatSlideToggle,CommonModule,MatIconModule,MatMenuModule,MatBadgeModule,MatButtonModule],
})

export class HeaderComponent{

  @Inject(AuthService)
  @Input() active = true;
  isMenuOpen = false;
  @Output() menuToggled = new EventEmitter<void>();
  public notificationCount: number = 0;
  private subscription!: Subscription;

  isDropdownOpen = false;

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  darkMode = signal<boolean>(
    JSON.parse(window.localStorage.getItem('darkMode') ?? 'false')
  );

  @HostBinding('class.dark') get mode() {
    return this.darkMode();
  }

  constructor(private themeService: ThemeService,private alertsService:AlertsService) {
    effect(() => {
      window.localStorage.setItem('darkMode', JSON.stringify(this.darkMode()));
        this.themeService.setDarkMode(this.darkMode());

    });
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.subscription = this.alertsService.alerts$.subscribe(alerts => {
      this.notificationCount = alerts.length;
    });
  }

  

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
    this.menuToggled.emit();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
