
<div class=" inline-flex m-auto mt-3 items-center ">
  <span> <mat-icon class="    mr-3 text-primary-500 dark:text-white text-8xl h-16 w-16 ">subscriptions</mat-icon></span>
  <h1   *ngIf="iscreate "  class="font-bold  text-2xl  text-primary-500 dark:text-white ">Save/View New Recording</h1>
</div>

<div mat-dialog-content class="flex flex-row space-x-2 pb-2">



      <form [formGroup]="form" class="w-1/3">
        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Project</mat-label>
          <mat-select placeholder="Select Project" formControlName="id_project">
            <mat-option [value]="0">dd</mat-option>
            <mat-option [value]="1">sq</mat-option>
            <mat-option [value]="2">q</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Zone</mat-label>
          <mat-select placeholder="Select Zone" formControlName="id_zone">
            <mat-option [value]="0">dd</mat-option>
            <mat-option [value]="1">sq</mat-option>
            <mat-option [value]="2">q</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Camera</mat-label>
          <mat-select placeholder="Select Camera" formControlName="id_camera">
            <mat-option [value]="0">dd</mat-option>
            <mat-option [value]="1">sq</mat-option>
            <mat-option [value]="2">q</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Choose a date</mat-label>
          <input matInput [matDatepicker]="datepicker" formControlName="date" />
          <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
          <mat-datepicker #datepicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Time From</mat-label>
          <input matInput [ngxTimepicker]="TimeFrom" [format]="24" formControlName="from"/>
          <ngx-material-timepicker-toggle [for]="TimeFrom"></ngx-material-timepicker-toggle>
          <ngx-material-timepicker #TimeFrom [theme]="darkTheme"></ngx-material-timepicker>
        </mat-form-field>

        <mat-form-field appearance="fill" class="w-full">
          <mat-label>Time To</mat-label>
          <input matInput [ngxTimepicker]="TimeTo" [format]="24" formControlName="to"/>
          <ngx-material-timepicker-toggle [for]="TimeTo"></ngx-material-timepicker-toggle>
          <ngx-material-timepicker #TimeTo [theme]="darkTheme"></ngx-material-timepicker>
        </mat-form-field>

        <div mat-dialog-actions class="flex justify-between">
          <button mat-button (click)="onCancel()" class=" h-10 px-6 font-semibold rounded-md bg-primary-500 text-white">Cancel</button>
          <button (click)="View()" [disabled]="!form.valid" class=" h-10 px-6 font-semibold rounded-md bg-primary-500 text-white">View</button>
          <button mat-button (click)="Save()" [disabled]="true" class=" h-10 px-6 font-semibold rounded-md bg-primary-500 text-white " >Save</button>

        </div>
      </form>



      <div #videoContainer class="video-container position-relative w-2/3 p-0" style="overflow: hidden;">
        <img #videoElement
             alt="MJPEG Stream"
             class="image-content position-absolute"
             [style.display]="videoPresent ? 'block' : 'none'"
             (load)="onImageLoad()"
             (error)="onImageError()" />

        <div class="image-content spinner-container" *ngIf="!videoPresent">
          <mat-spinner [diameter]="125"></mat-spinner>
        </div>
      </div>

</div>
