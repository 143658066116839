import { Component, signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SharedTableComponent } from '../../shared/components/shared-table/shared-table.component';
import { PageEvent } from '@angular/material/paginator';
import { FormsModule } from '@angular/forms';
import { displayedColumns } from './table-config';
import { MatDialog } from '@angular/material/dialog';
import { FilterDataPipe } from '../../shared/pipes/filter-data.pipe';
import { UserService } from '../../services/users/users.service';
import { AddUserComponent } from '../../components/add-user/add-user.component';
import { AssigneRoleComponent } from '../../components/assigne-role/assigne-role.component';
import { AssigneRole2Component } from '../../components/assigne-role copy/assigne-role.component';

@Component({
  selector: 'app-users-list',
  standalone: true,
  imports: [
    MatIconModule, CommonModule,
    MatButtonModule, MatMenuModule, SharedTableComponent, FormsModule, FilterDataPipe
  ],
  templateUrl: './users-list.component.html',
  styleUrl: './users-list.component.scss'
})
export class UsersListComponent {
  searchText: string = '';
  dataSource: any;
  displayedColumns = displayedColumns;
  totalItems = 0;
  loadingTable = signal(false);
  filterText = '';

  constructor(private readonly userService: UserService, private readonly dialog: MatDialog) { }

  ngOnInit() {
    this.getAllUsers();
  }

  getAllUsers(skip?: any, limit?: any) {
    this.loadingTable.set(true);
    this.userService.getAllUsers(skip, limit).subscribe(
      (response) => {
        this.dataSource = response;
        this.totalItems = response.length;
        this.loadingTable.set(false);
      }
    );
  }

  changedPage(event: PageEvent) {
    const limit = event.pageSize;
    const skip = event.pageIndex * event.pageSize;
    this.getAllUsers(skip, limit);
  }

  changedSort(sort: any) {
    console.log('sort ', sort);
  }

  rowSelect(event: any) {
    console.log('event row select ', event);
  }

  actionOnTable(event: { action: string, row: any }) {
    console.log('event action on table', event);
    switch (event.action) {
      case 'role':
        if (this.isAdmin()) {
          this.openRoleDialog(event.row);
        }
        break;
      case 'project':
        this.openProjectDialog(event.row);
        break;
      case 'deactivate':
        // this.openUpdateDialog(event.row);
        break;
      default:
        break;
    }

  }

  onChangeFilter(event: { column: string, value: string[] }) {
    console.log('event ', event);
  }

  goToAddUser() {
    console.log('go to add user');
    this.openCreateDialog();
  }

  openCreateDialog(): void {
    this.dialog.open(AddUserComponent, {
     width: '75vw',
   }).afterClosed().subscribe((result) => {
      this.getAllUsers();
   });
  }


  openRoleDialog(user:any): void {
    this.dialog.open(AssigneRoleComponent, {
     width: '75vw',
     data:  user
   }).afterClosed().subscribe((result) => {
   });
  }

  openProjectDialog(user:any): void {
    this.dialog.open(AssigneRole2Component, {
     width: '75vw',
     data:  user
   }).afterClosed().subscribe((result) => {
   });
  }

  isAdmin(): boolean {
    let user= JSON.parse(localStorage.getItem('CamAppUser')??'')
    return user.roles.some((role: any) => role.name === 'ADMIN');
  }
  isRespo(): boolean {
    let user= JSON.parse(localStorage.getItem('CamAppUser')??'')
    return user.roles.some((role: any) => role.name === 'RESPONSABLE SITE');
  }
}
