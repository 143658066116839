import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [ReactiveFormsModule,
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit {
  userProfile: any;
  userInitials: string = '';
  isEditing: boolean = false;
  editForm: FormGroup;

  constructor(private fb: FormBuilder) {
    this.editForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      password: ['', [Validators.minLength(8)]]
    });
  }

  ngOnInit() {
    // In a real application, you would get this data from a service
    if (localStorage.getItem('CamAppUser')) {
      this.userProfile = JSON.parse(localStorage.getItem('CamAppUser')??'');
      console.log(this.userProfile);
      this.userInitials = this.getInitials(this.userProfile.firstName+' '+this.userProfile.lastName)
    }else{

    }

  }

  // getUserProfile(): void {
  //   this.userService.getUserProfile().subscribe((profile) => {
  //     this.userProfile = profile;
  //     this.editForm.patchValue({
  //       username: profile.username,
  //       email: profile.email,
  //       firstName: profile.firstName,
  //       lastName: profile.lastName,
  //     });
  //   });
  // }

  toggleEdit() {
    this.isEditing = !this.isEditing;
    if (this.isEditing) {
      this.editForm.patchValue({
        email: this.userProfile.email,
        firstName: this.userProfile.firstName,
        lastName: this.userProfile.lastName,
        password: ''
      });
    } else {
      this.editForm.reset();
    }
  }

  onSubmit() {
    if (this.editForm.valid && !this.editForm.pristine) {
      // In a real application, you would call a service to update the user profile
      const formValue = this.editForm.value;

      // Update only changed values
      this.userProfile = {
        ...this.userProfile,
        name: formValue.name,
        email: formValue.email,
        firstName: formValue.firstName,
        lastName: formValue.lastName};

      // Handle password change if provided
      if (formValue.password) {
        console.log('Password change requested');
        // Implement password change logic
      }

      this.userInitials = this.getInitials(this.userProfile.firstName+' '+this.userProfile.lastName);
      this.toggleEdit();
    }
  }

  private getInitials(name: string): string {
    return name
      .split(' ')
      .map(part => part.charAt(0))
      .join('')
      .toUpperCase();
  }
}
