import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-custom-dialog',
  standalone: true,
  imports: [MatDialogModule,MatButtonModule,CommonModule,MatIcon],
  templateUrl: './custom-dialog.component.html',
  styleUrl: './custom-dialog.component.scss'
})
export class CustomDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any) {}

}
