import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CameraService {

  
  CamerasAllUrl= "cameras/zones/";
  AddCameraUrl= "cameras/";
  DeleteCameraUrl= "cameras/";
  UpdateCameraUrl= "cameras/";

  ARDUINOTESTURL= "sensors/receive";


  constructor(private http: HttpClient) { 
  }
  
  getAllCameras(project_id:any): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(`${environment.ApiUrl+this.CamerasAllUrl+project_id}`,{ headers: headers });
    
  }

  addCamera(data:any): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.post<any>(`${environment.ApiUrl+this.AddCameraUrl}`,data,{ headers: headers });
    
  }

  updateCamera(data:any): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.put<any>(`${environment.ApiUrl+this.UpdateCameraUrl+data.id}`,data,{ headers: headers });
    
  }

  deleteCamera(Camera_id:number): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.delete<any>(`${environment.ApiUrl+this.DeleteCameraUrl+Camera_id}`,{ headers: headers });
    
  }

  ARDUINO(data:any): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.post<any>(`${environment.ApiUrl+this.ARDUINOTESTURL}`,data,{ headers: headers });
    
  }

}
