<div >
  <div mat-button [matMenuTriggerFor]="menu" [class.text-right]="tableColumn.position === 'right'"
    class="filter-header-title" [matTooltip]="(tableColumn.name)">
    <mat-icon class="material-symbols-outlined ">
      filter_alt
    </mat-icon>
    {{(tableColumn.name)}}
  </div>
  <mat-menu #menu="matMenu" [formGroup]="filterForm">
    @for (item of tableColumn.filterHeaderOption; track item; let index = $index) {
      <div class="px-4 py-2"
        formArrayName="options">
        <mat-checkbox [formControlName]="index" (change)="filterChanged($event, item.value, index)">{{item.label}}</mat-checkbox>
      </div>
    }
  </mat-menu>
</div>
