import { createDateSimpleColumn, createImageColumn, createSimpleColumn, createUrlColumn } from "../../shared/config-table";
import { TableColumn } from "../../shared/models/table-columns.model";

export const displayedColumns: TableColumn[] = [
  createSimpleColumn('camera', 'camera_name',true),
  createSimpleColumn('Object type','object_class',false),
  createDateSimpleColumn('Captured date', 'captured_at',true),
  createSimpleColumn('Confidence','confidence',true),
  createImageColumn('image viewer', 'path', false),
];
