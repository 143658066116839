import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ZonesService {

  ZonesAllUrl= "zones/projects/";
  AddZoneUrl= "zones/";
  DeleteZoneUrl= "zones/";
  UpdateZoneUrl= "zones/";

  ARDUINOTESTURL= "sensors/receive";


  constructor(private http: HttpClient) { 
  }
  
  getAllZones(project_id:any): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(`${environment.ApiUrl+this.ZonesAllUrl+project_id}`,{ headers: headers });
    
  }

  addZone(data:any): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.post<any>(`${environment.ApiUrl+this.AddZoneUrl}`,data,{ headers: headers });
    
  }

  updateZone(data:any): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.put<any>(`${environment.ApiUrl+this.UpdateZoneUrl+data.id}`,data,{ headers: headers });
    
  }

  deleteZone(Zone_id:number): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.delete<any>(`${environment.ApiUrl+this.DeleteZoneUrl+Zone_id}`,{ headers: headers });
    
  }

  ARDUINO(data:any): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.post<any>(`${environment.ApiUrl+this.ARDUINOTESTURL}`,data,{ headers: headers });
    
  }

}
