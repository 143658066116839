<div class="mt-4">
  <div class="mb-3">
    <div class="flex items-center px-4 py-2 font-bold mb-5 align-middle m-auto">
      <mat-icon class="mat-18 text-5xl w-10 h-8 text-primary-500 dark:text-white" >manage_accounts</mat-icon>
      <p class="ml-3 text-2xl text-primary-500 dark:text-white mt-2">Users List</p>
    </div>
  </div>

  <div class="flex flex-col sm:flex-row justify-between items-center mb-6 mt-10 space-y-4 sm:space-y-0 sm:space-x-4">
    <div class="flex flex-row gap-2 items-center sm:w-2/3 w-full">
      <!-- Search Bar -->
      <div class="flex items-center border rounded bg-white pl-2 sm:w-2/3 w-full">
        <input
          type="text"
          placeholder="Search for a user"
          [(ngModel)]="filterText"
          class="focus:outline-none text-sm flex-1"
        />
        <button class="bg-primary-500 rounded hover:bg-primary-700 w-10 h-8 sm:w-12 sm:h-10">
          <mat-icon class="text-white m-auto align-middle">search</mat-icon>
        </button>
      </div>

    </div>
    <button *ngIf="isAdmin()" class="bg-primary-500 rounded hover:bg-primary-700 w-10 h-8 sm:w-12 sm:h-10" (click)="goToAddUser()">
      <mat-icon class="text-white m-auto align-middle">add</mat-icon>
    </button>
  </div>

  <div class="overflow-x-auto">
    <div class="flex flex-col items-center h-fit w-full relative">
      <app-shared-table class="w-full" [tableData]="dataSource | filterData:filterText:'username'" [tableColumns]="displayedColumns" [totalItems]="totalItems"
        (sortEvent)="changedSort($event)" (actionRowEvent)="rowSelect($event)" (pageChangedEvent)="changedPage($event)"
        (filtersChangedEvent)="onChangeFilter($event)" (actionEmittedEvent)="actionOnTable($event)">
      </app-shared-table>
    </div>
    <div *ngIf="!(totalItems > 0)">
      <div *ngIf="loadingTable()" class="flex space-x-4 py-1">
        <div class="h-8 w-full bg-gray-400 rounded animate-pulse"></div>
      </div>
    </div>
  </div>
</div>
