<div *ngIf="cameras; else shimmerGrid" class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mx-2 text-white">
  <div *ngFor="let camera of cameras" class="bg-white rounded-lg shadow-md">
    <div class="p-4">
      <div class="wrap">
        <img class="w-full h-[45vh] object-cover rounded" [src]="camera.thumbnailUrl | safe" alt="Camera thumbnail">
      </div>
    </div>
    <div class="p-2 text-center">
      <b>{{ camera.name }}</b>
    </div>
  </div>
</div>

<ng-template #shimmerGrid>
  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mx-2 text-white">
    <div *ngFor="let i of [1, 2, 3, 4, 1, 2, 3, 4]" class="bg-white rounded-lg shadow-md">
      <div class="p-4">
        <div class="wrap">
          <!-- <ngx-skeleton-loader appearance="custom-content" [theme]="{ 'height': '100%', 'width': '100%' }"></ngx-skeleton-loader> -->
        </div>
      </div>
      <div class="p-2 text-center">
        <!-- <ngx-skeleton-loader appearance="custom-content" [theme]="{ 'height': '20px', 'width': '60%', 'margin': '0 auto' }"></ngx-skeleton-loader> -->
      </div>
    </div>
  </div>
</ng-template>
