 <div class="background-container bg-cover bg-center">
  <div class="min-h-screen flex items-center justify-end w-full p-8">
    <div class="bg-gradient-to-b from-primary-500 to-primary-300 dark:bg-primary-50 shadow-md rounded-lg px-8 py-6 max-w-md w-full lg:w-1/3 h-[80vh] flex flex-col justify-start">

      <!-- Logo Section -->
      <div class="flex justify-center mb-8">
        <img src="assets/images/logo_login.svg" alt="Logo" class="h-32 w-auto"> <!-- Adjust the height as needed for the logo -->
      </div>

      <!-- Form Section -->
      <h1 class="text-2xl font-bold text-center mb-4 text-gray-800 dark:text-white">Welcome Back!</h1>
      <form [formGroup]="LoginFrom" class="space-y-6 flex-grow flex flex-col justify-between">

        <div>
          <label for="email" class="block text-sm font-medium mb-2 text-gray-800 dark:text-gray-300">Email Address</label>
          <input
            type="email"
            formControlName="email"
            class="rounded-md w-full px-3 py-2 border border-primary-300 dark:border-gray-600 dark:bg-gray-800 text-gray-800 dark:text-white placeholder-gray-500 dark:placeholder-gray-400"
            placeholder="your@email.com"
            required
          >
        </div>

        <div>
          <label for="password" class="block text-sm font-medium mb-2 text-gray-800 dark:text-gray-300">Password</label>
          <input
            type="password"
            formControlName="password"
            class="rounded-md w-full px-3 py-2 border border-primary-300 dark:border-gray-600 dark:bg-gray-800 text-gray-800 dark:text-white placeholder-gray-500 dark:placeholder-gray-400"
            placeholder="Enter your password"
            required
          >
          <a href="#" class="text-xs text-primary-500 hover:text-primary-400">Forgot Password?</a>
        </div>

        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <input type="checkbox" id="remember" class="h-4 w-4 rounded border-primary-300 text-primary-600 focus:ring-primary-500 focus:outline-none" checked>
            <label for="remember" class="ml-2 block text-sm text-gray-800 dark:text-gray-300">Remember me</label>
          </div>
        </div>

        <div class="flex">
          <button
            [disabled]="submitted"
            mat-button
            (click)="login()"
            type="submit"
            class="w-48 mx-2 justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            <span class="button-content">
              <mat-spinner *ngIf="submitted" [diameter]="25"></mat-spinner>
              <span *ngIf="!submitted">Login</span>
            </span>
          </button>
          <button
            type="submit"
            class="w-48 mx-2 justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            Register
          </button>
        </div>

      </form>
    </div>
  </div>
</div>




