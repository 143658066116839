import { MatCardModule } from '@angular/material/card';
import { Component,inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIcon } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute} from '@angular/router';
import { AddSensorComponent } from '../../components/add-sensor/add-sensor.component';
import { SensorService } from '../../services/sensor/sensor.service';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ThemeService } from '../../services/theme.service';
import { CustomDialogComponent } from '../../shared/components/custom-dialog/custom-dialog.component';

@Component({
  selector: 'app-sensors',
  standalone: true,
  imports: [MatProgressSpinnerModule, CommonModule,MatCardModule,MatButtonModule,MatIcon],
  templateUrl: './sensors.component.html',
  styleUrl: './sensors.component.scss'
})
export class SensorsComponent {

  cardId:any = null;
  zoneId:any = null;
  zoneName:string | null = "";
  cardName:string | null = "";
  sensors:any = null;
  breakpoint:number = 4;
  refresh:boolean = false;
  IsDarkMode:boolean = false;

  readonly deleteDialog = inject(MatDialog);
  readonly dialog = inject(MatDialog);

constructor(private readonly darkMode:ThemeService,private readonly route: ActivatedRoute,
  private readonly sensorservice: SensorService,private readonly _snackBar: MatSnackBar) {}

  ngOnInit(): void {

    this.darkMode.isDarkMode$.subscribe(value =>{
      this.IsDarkMode = value;
    });

    this.route.paramMap.subscribe(params => {
      this.cardId = params.get('card_id');
      this.zoneId = params.get('zone_id');
      this.zoneName = params.get('zone_name');
      this.cardName = params.get('card_name');
      setTimeout(() => {
        this.getSensors();
      }, 1000);

      setInterval(() => {
        this.getSensors();
      }, 10000);
    });
  }


  openCreateDialog(): void {
    this.dialog.open(AddSensorComponent, {
      width: '75vw',
      data : {cardId:this.cardId,zoneId:this.zoneId},
       panelClass: this.IsDarkMode ? 'dark' : ''
    }).afterClosed().subscribe((result) => {
        this.getSensors();
    });
  }

  openDeleteDialog(sensor_id:number,enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.deleteDialog.open(CustomDialogComponent, {
      panelClass: 'responsive-dialog',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { sensor_id: sensor_id,
        title: "Delete Sensor",
        message: "Are you sure you want to delete this sensor?",
       }
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.deleteSensor(sensor_id);

      }

    });
  }
  deleteSensor(sensor_id:number){
    this.sensorservice.deleteSensor(sensor_id).subscribe(
      (response) => {
        if(response && response.message){
          this.openSnackBar(response.message,"close")
          this.getSensors();
        }else{
          this.openSnackBar("Error Server Try again","close")
        }
      }
    );
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  getSensors(){
    this.refresh = true;
    this.sensorservice.getAllSensors(this.cardId).subscribe(
      (response) => {
        if (response) {
          this.sensors = response;
          setTimeout(() => {   this.refresh = false; }, 1000);
        }
      }
    );



  }

}

