import { ChangeDetectionStrategy, Component,Inject,inject } from '@angular/core';
import {  MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardActions, MatCardContent, MatCardFooter, MatCardImage } from '@angular/material/card';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SafePipe } from '../../shared/pipes/safe.pipe';
import { ProjectService } from '../../services/project/project.service';
import { CommonModule } from '@angular/common';
import { MatChip, MatChipSet, MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { AddProjectComponent } from '../../components/add-project/add-project.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { ThemeService } from '../../services/theme.service';
import { FormsModule, } from '@angular/forms';
import { FilterDataPipe } from '../../shared/pipes/filter-data.pipe';

@Component({
  selector: 'app-projects',
  standalone: true,
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  imports: [FormsModule, RouterLink,MatChip,MatChipsModule, MatChipSet,MatIconModule, CommonModule, MatCard,MatCardContent,MatCardActions,MatCardFooter,MatCardImage,MatGridList,MatGridTile,MatButtonModule,SafePipe,FilterDataPipe],

})

export class ProjectsComponent {

  searchText: string = '';
  breakpoint:number = 4;
  projects: any;
  IsDarkMode:boolean = false;
  readonly deleteDialog = inject(MatDialog);
  readonly dialog = inject(MatDialog);

  constructor(private darkMode:ThemeService,private projectService:ProjectService,private _snackBar: MatSnackBar){
  }

  configSnakebar = {
    duration: 30000,
    panelClass: ['warning-snackbar'],
    verticalPosition: 'top'
  }

  ngOnInit() {

    this.darkMode.isDarkMode$.subscribe(value =>{
      this.IsDarkMode = value;
    });

    this.breakpoint = (window.innerWidth <= 768) ? 1 : 4;

    // this.openSnackBar('You have an alert from camera number 3 you have to checkOut','close')

    setTimeout(() => {
      this.getProjects();
    }, 500);


  }

  onResize(event:any) {
    this.breakpoint = (event.target.innerWidth <= 768) ? 1 : 4;
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  getProjects() {

    this.projectService.getAllProjects().subscribe(
      (response) => {
      this.projects = response;
      }
    );
  }

  openCreateDialog(): void {
     this.dialog.open(AddProjectComponent, {
      width: '75vw',
      panelClass: this.IsDarkMode ? 'dark' : ''
    }).afterClosed().subscribe((result) => {
        this.getProjects();
    });
  }

  openUpdateDialog(project:any): void {
    this.dialog.open(AddProjectComponent, {
     width: '75vw',
     data:project,
     panelClass: this.IsDarkMode ? 'dark' : ''
   }).afterClosed().subscribe((result) => {
       this.getProjects();
   });
 }


  openDeleteDialog(project_id:number,enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.deleteDialog.open(DialogDeleteProject, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { project_id: project_id },
      panelClass: this.IsDarkMode ? 'dark' : ''
    }).afterClosed().subscribe((result) => {
        this.getProjects();
    });

  }

}

@Component({
  selector: 'delete-project-dialog',
  templateUrl: '../../shared/templates/delete-project-dialog.html',
  standalone: true,
  imports: [MatButtonModule,MatDialogModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogDeleteProject {
  readonly dialogRef = inject(MatDialogRef<DialogDeleteProject>);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { project_id: number }
  ,private projectService:ProjectService,private _snackBar: MatSnackBar){

  }

  deleteProject(project_id:number){
    this.projectService.deleteProject(project_id).subscribe(
      (response) => {
        if(response.message){
          this.openSnackBar(response.message,"close")
          this.dialogRef.close(true);
        }
      }
    );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

}
