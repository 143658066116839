import { Component} from '@angular/core';
import {  MatButtonModule } from '@angular/material/button';
import { SafePipe } from '../../shared/pipes/safe.pipe';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { CameraService } from '../../services/camera/camera.service';


@Component({
  selector: 'app-cameraswatch',
  standalone: true,
  imports: [CommonModule,MatButtonModule,SafePipe],
  templateUrl: './cameraswatch.component.html',
  styleUrl: './cameraswatch.component.scss'
})
export class CameraswatchComponent {


  breakpoint:number = 4;
  zoneId:any = null;
  zoneName:any = null;
  cameras:any = null;

  constructor(private route: ActivatedRoute,private cameraservice:CameraService){

  }

  ngOnInit() {

    this.breakpoint = (window.innerWidth <= 768) ? 1 : 4;
    this.route.paramMap.subscribe(params => {
      this.zoneId = params.get('zone_id');
      this.zoneName = params.get('zone_name');
      setTimeout(() => {
        this.getCameras();
      }, 5000);
    });

  }

  getCameras(){

    this.cameraservice.getAllCameras(this.zoneId).subscribe(
      (response) => {
        if (response) {
          this.cameras = response;
        }
      }
    );
  }

  onResize(event:any) {
    this.breakpoint = (event.target.innerWidth <= 768) ? 1 : 4;
  }

}
