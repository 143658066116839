import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RecordingService } from '../../services/recording/recording.service';
import { MAT_DIALOG_DATA, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { Recording } from '../../core/types/Recording';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {  MatFormFieldModule  } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import {  MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule, provideNativeDateAdapter } from '@angular/material/core';
import {NgxMaterialTimepickerModule, NgxMaterialTimepickerTheme} from 'ngx-material-timepicker';
import { environment } from '../../../environments/environment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatGridListModule, MatGridTile } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-create-recording',
  standalone: true,
  providers: [provideNativeDateAdapter(),{provide: MAT_DATE_LOCALE, useValue: 'fr-FR'}],
  imports: [ ReactiveFormsModule,
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDatepickerToggle,
    NgxMaterialTimepickerModule,
    MatProgressSpinnerModule,
    MatGridListModule,MatGridTile,MatDialogTitle,MatDialogContent,
    MatIconModule
    ],
    templateUrl: './create-recording.component.html',
    styleUrl: './create-recording.component.scss'
})
export class CreateRecordingComponent {

  @ViewChild('videoElement') videoElement!: ElementRef<HTMLImageElement>;
  @ViewChild('videoContainer') videoContainer!: ElementRef<HTMLDivElement>;

  videoPresent = false; // If taking Screen this will be true to show loader
  currentTime: any = null;
  url = '';//http://192.168.1.78:8000/video_feed

  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
        bodyBackgroundColor: '#2f2928',
        buttonColor: '#ffb4a8'
    },
    dial: {
        dialBackgroundColor: '#555',
    },
    clockFace: {
        clockFaceBackgroundColor: '#555',
        clockHandColor: '#ffb4a8',
        clockFaceTimeInactiveColor: '#fff'
    }
};

  form: FormGroup;
  iscreate:boolean = true;

  configSnakebar = {
    duration: 30000,
    panelClass: ['warning-snackbar'],
    verticalPosition: 'top'
  }

  constructor(
    private _snackBar: MatSnackBar,
    private recordinservice:RecordingService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<CreateRecordingComponent>,
    @Inject(MAT_DIALOG_DATA) public data:   Recording
  ) {
    this.form = this.formBuilder.group({
      id_project: ['', Validators.nullValidator],
      id_zone: ['', Validators.nullValidator],
      id_camera: ['', Validators.nullValidator],
      from: ['' , Validators.required],
      to: ['', Validators.required],
      date: ['', Validators.required],
      description: ['', Validators.nullValidator]
    });

  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.startVideoStream();
  }

  onCancel(): void {
    this.form.reset();
    this.dialogRef.close();
  }

  onSubmit(): void {

    if (this.iscreate) {
      if (this.form.valid) {
        this.recordinservice.createRecording(this.form.value).subscribe(
          (response) => {
            this.openSnackBar(response.message,"close");
            this.dialogRef.close(true);
          }
        );
      }
    }

  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }


  View(){

    if (this.form.valid) {
     this.startRecording(environment.ApiRecording+"playback?channel=channel_1&date="+this.formatDate(this.form.value.date, 'YYYY-MM-DD')+"&start_time="+this.form.value.from+":00&end_time="+this.form.value.to+":00");
    }else{
      console.log("error");

    }
  }


  Save(){}

  formatDate(date: Date, formatString: string): string {
    const options: { [key: string]: number | string } = {
        'YYYY': date.getFullYear(),
        'YY': String(date.getFullYear()).slice(-2),
        'MM': String(date.getMonth() + 1).padStart(2, '0'), // Months are 0-indexed
        'M': date.getMonth() + 1,
        'DD': String(date.getDate()).padStart(2, '0'),
        'D': date.getDate(),
        'HH': String(date.getHours()).padStart(2, '0'),
        'hh': String(date.getHours() % 12 || 12).padStart(2, '0'), // 12-hour format
        'mm': String(date.getMinutes()).padStart(2, '0'),
        'ss': String(date.getSeconds()).padStart(2, '0'),
        'a': date.getHours() < 12 ? 'am' : 'pm',
        'A': date.getHours() < 12 ? 'AM' : 'PM',
    };

    return formatString.replace(/YYYY|YY|MM|M|DD|D|HH|hh|mm|ss|a|A/g, (matched) => {
        return String(options[matched]);
    });
}

startRecording(recordingUrl: string) {
  this.videoPresent = false;
  this.videoElement.nativeElement.src = '';
  this.url = recordingUrl;
  this.startVideoStream();
}

private startVideoStream(): void {

  if (this.videoElement && this.videoElement.nativeElement) {

    // Set the video source URL for MJPEG stream
    this.videoElement.nativeElement.src = this.url;

    this.videoElement.nativeElement.classList.add("image-content");
    this.videoElement.nativeElement.classList.remove("image-content-no");

    // Event listener for when the video is ready to play
    this.videoElement.nativeElement.oncanplay = () => {

      this.videoElement.nativeElement.classList.add("image-content");
      this.videoElement.nativeElement.classList.remove("image-content-no");
      this.videoPresent = true;
    };

    // Optional: Handle errors
    this.videoElement.nativeElement.onerror = () => {
      this.videoPresent = true;
      this.videoElement.nativeElement.classList.add("image-content-no");
      this.videoElement.nativeElement.classList.remove("image-content");
      this.videoElement.nativeElement.src =
        '../../assets/images/no-video.png';
    };
  } else {
    this.videoPresent = true;
  }

}

onImageLoad(): void {
  this.videoPresent = true;
}

onImageError(): void {
  this.videoPresent = true;
  this.videoElement.nativeElement.src =
    '../../assets/images/no-video.png';
}
}
