<div class="background-container bg-cover bg-center">
  <div class="min-h-screen flex items-center justify-center p-4 sm:p-8 md:justify-end">
    <div class="bg-gradient-to-b from-primary-500 to-primary-300 dark:bg-primary-50 shadow-md rounded-lg px-4 py-6 sm:px-8 sm:py-8 max-w-md w-full  h-auto  flex flex-col justify-start">

      <!-- Logo Section -->
      <div class="flex justify-center mb-4 sm:mb-8">
        <img src="assets/images/logo_login.png" alt="Logo" class="h-24 lg:h-32 xl:h-44 w-auto"> <!-- Adjust the height as needed for the logo -->
      </div>

      <!-- Form Section -->
      <h1 class="text-xl sm:text-2xl font-bold text-center mb-2 sm:mb-4 dark:text-gray-300 text-white">Welcome Back!</h1>
      <form [formGroup]="LoginFrom" class="space-y-6 sm:space-y-6 flex-grow flex flex-col justify-between">

        <div>
          <label for="email" class="block text-sm font-medium mb-1 sm:mb-2 dark:text-gray-300 text-white">Email Address</label>
          <input
            type="email"
            formControlName="email"
            class="rounded-md w-full px-3 py-2 border border-primary-300 dark:border-gray-600 dark:bg-gray-800 text-gray-800 dark:text-white placeholder-gray-500 dark:placeholder-gray-400"
            placeholder="your@email.com"
            required
          >
        </div>

        <div>
          <label for="password" class="block text-sm font-medium mb-1 sm:mb-2 dark:text-gray-300 text-white">Password</label>
          <input
            type="password"
            formControlName="password"
            class="rounded-md w-full px-3 py-2 border border-primary-300 dark:border-gray-600 dark:bg-gray-800 text-gray-800 dark:text-white placeholder-gray-500 dark:placeholder-gray-400"
            placeholder="Enter your password"
            required
          >
          <a href="#" class="text-xs text-primary-100 hover:text-primary-500">Forgot Password?</a>
        </div>

        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <input type="checkbox" id="remember" class="h-4 w-4 rounded border-primary-300 text-primary-600 focus:ring-primary-500 focus:outline-none" checked>
            <label for="remember" class="ml-2 block text-sm dark:text-gray-300 text-white">Remember me</label>
          </div>
        </div>

        <div class="flex flex-col sm:flex-row">
          <button
            [disabled]="submitted"
            mat-button
            (click)="login()"
            type="submit"
            class="w-full sm:w-48 mx-0 sm:mx-2 mb-2 sm:mb-0 justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            <span class="button-content">
              <mat-spinner *ngIf="submitted" [diameter]="25"></mat-spinner>
              <span *ngIf="!submitted">Login</span>
            </span>
          </button>
          <button
            type="submit"
            class="w-full sm:w-48 mx-0 sm:mx-2 justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
            Register
          </button>
        </div>

      </form>
    </div>
  </div>
</div>
