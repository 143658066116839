<ng-container  class="flex flex-row justify-end">
  @if (tableColumn.actions.length>0 && containsActionsToHide()) {
    @for (action of tableColumn.actions; track action) {
      @if (showBtn(action)) {
        <button mat-icon-button (click)="$event.stopPropagation(); $event.preventDefault(); onAction(action.action)"
        [matTooltip]=" disableBtn(action) ? (action.disableReasonText) : action.label" [disabled]="disableBtn(action)">
        @if (action.materialIconName) {
          <mat-icon class="material-symbols-outlined "> {{action.materialIconName}} </mat-icon>
        }
      </button>
      }
    }
  }
</ng-container>
