<div class="flex justify-between items-center   text-primary-500 p-5 font-semibold text-lg  ">
  <div class="flex justify-start items-center     ">
    <button class="flex transition-transform hover:scale-125 align-left">
      <span (click)="toggleMenu()" class="material-icons text-[40px]">menu</span>
    </button>
  </div>

  <div class="flex justify-end items-center">

    <button class="relative w-12 h-6 bg-blue-200 rounded-full transition-transform mx-5 flex items-center   "
      [ngClass]="darkMode() ? 'bg-primary-500' : 'bg-white border'" (click)="darkMode.set(!darkMode())">
      <!-- Toggle Thumb -->
      <span class="absolute w-5 h-5 rounded-full transition-all duration-300"
        [ngClass]="darkMode() ? 'translate-x-6 bg-white' : 'translate-x-1 bg-primary-500'"></span>
      <!-- Optional mode icons -->
      <span *ngIf="!darkMode()" class="material-icons absolute left-2 text-white  text-sm">light_mode</span>
      <span *ngIf="darkMode()" class="material-icons absolute right-2 text-primary-700 text-sm">dark_mode</span>
    </button>

    <button class="flex transition-transform hover:scale-125 mx-5" [routerLink]="['/notifications']"  >
      <mat-icon [matBadge]="notificationCount" class="badge_icon" matBadgeColor="warn" matBadgeSize="small"
      matBadgeOverlap="true" [matBadgeHidden]="notificationCount === 0" aria-hidden="false">
      notifications
    </mat-icon>
    </button>

  </div>
</div>