import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CamerasKpiService {

  kpiUrl= "cameras/data/";


  constructor(private http: HttpClient) {
  }

  getAllCamerasKpi(skip: any=0,limit: any=10): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(`${environment.ApiUrl+this.kpiUrl}?skip=${skip}&limit=${limit}`,{ headers: headers });

  }

}
