
<div class="p-4 ">
  <!-- Header Section -->
  <!-- Filter Button -->

  <div class="flex items-center  px-4 py-2   font-bold mb-5 align-middle m-auto">
    <mat-icon class="mat-18 text-5xl w-10 h-8 text-primary-500 dark:text-white">broadcast_on_home</mat-icon>
    <p class="ml-3 text-2xl text-primary-500 dark:text-white mt-2">sensors List of {{cardName}} of {{zoneName}} </p>
  </div>

  <div class="flex flex-col sm:flex-row justify-between items-center mb-6 mt-10 space-y-4 sm:space-y-0 sm:space-x-4">

    <div class="flex flex-row gap-2 items-center sm:w-2/3 w-full">

      <!-- Search Bar -->
      <div class="flex items-center border rounded bg-white pl-2 sm:w-2/3 w-full">
        <input
          type="text"
          placeholder="Search for a project"
          class="focus:outline-none text-sm flex-1"
        />
        <button class="bg-primary-500 rounded hover:bg-primary-700 w-10 h-8 sm:w-12 sm:h-10">
          <mat-icon class="text-white m-auto align-middle">search</mat-icon>
        </button>
      </div>

      <button class="bg-primary-500 rounded hover:bg-primary-700 w-10 h-8 sm:w-12 sm:h-10">
        <mat-icon class="text-white m-auto align-middle">filter_alt</mat-icon>
      </button>
    </div>


    <button class="bg-primary-500 rounded hover:bg-primary-700 w-10 h-8 sm:w-12 sm:h-10" (click)="openCreateDialog()">
      <mat-icon class="text-white m-auto align-middle">add</mat-icon>
    </button>
  </div>


  <!-- Project Grid/List Section -->
  <ng-container *ngIf="sensors; else shimmer">
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6"
      *ngIf="sensors?.length > 0; else empty">


      <div class="relative group max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
        *ngFor="let sensor of sensors" >


          <button (click)="openDeleteDialog(sensor.id,'400ms','200ms')"
            class="absolute right-1 top-[-25px] transform translate-y-full opacity-0 group-hover:opacity-100 transition-opacity duration-300 ">
            <mat-icon class="mat-18" class=" text-white bg-primary-500  rounded hover:bg-primary-700 ">delete</mat-icon>
          </button>


        <div class="p-5">
          <a >
            <h5 class="text-m font-semibold tracking-tight text-gray-900 dark:text-white mr-3 mb-3">{{sensor.name}}
            </h5>
          </a>

          <div class="grid w-full">

            <!-- Fourth Item -->
            <div class="flex justify-center  "   >
              <span *ngIf="!refresh">{{sensor?.latest_sensor_data?.value}} {{sensor?.unit}}</span>
              <mat-spinner [diameter]="20" *ngIf="refresh"></mat-spinner>
            </div>
          </div>

        </div>
      </div>

    </div>
  </ng-container>


  <ng-template #shimmer>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
      <div
        class="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 animate-pulse"
        *ngFor="let n of [1,2,3,4,5,6,7,8]">
        <div class="p-5">
          <div class="h-5 bg-gray-300 rounded mb-3"></div> <!-- Placeholder for Project Name -->

          <div class="grid ">

            <div class="flex justify-end">
              <div class="h-4 w-4 bg-gray-300 rounded-full"></div> <!-- Placeholder for Icon -->
              <div class="h-4 bg-gray-300 rounded ml-3 w-12"></div> <!-- Placeholder for Cameras Count -->
            </div>

          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #empty>
    <div class="flex flex-col items-center justify-center p-6  mt-5 ">
      <!-- SVG Image -->

      <mat-icon class="mat-18 text-[200px] w-full h-full text-center m-auto">folder</mat-icon>
      <!-- Text -->
      <h3 class="text-lg font-semibold text-gray-900 dark:text-white mb-2">No sensors Yet</h3>
      <p class="text-gray-500 dark:text-gray-400 text-center">Create a new sensor to get started.</p>

      <!-- Button -->
      <button (click)="openCreateDialog()"
        class="mt-16 px-4 py-2 bg-primary-500 text-white rounded-lg hover:bg-primary-700 focus:outline-none focus:ring ">
        Create New sensor
      </button>
    </div>

  </ng-template>

</div>

