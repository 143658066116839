import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-status-cell',
  templateUrl: './status-cell.component.html',
  styleUrls: ['./status-cell.component.scss'],
  standalone:true,
  imports:[
    CommonModule,
    MatTooltipModule,
  ]
})
export class StatusCellComponent {
  @Input() tableColumn: any;
  @Input() element: any;

  getColor(etat: string): string {
    switch (etat) {
      case 'activated':
      case '1-A_AFFINER':
        return '#70c067'; // Green
      case 'deactivated':
      case '2-A_DEVELOPPER':
        return '#df352a';
      case '1- Hight':
      case '3-EC_DEV':
        return '#ff0000';
      case '2- Medium':
      case '4-A_RU':
        return '#ffa500';
      case '3- Low':
      case '5-EC_RU':
        return '#ffff00';
      case '6-A_VALIDER':
        return '#6495ed';
      case '7-VALIDE':
        return '#dc143c';
      default:
        return '#b1b3b8';
    }
  }

}
