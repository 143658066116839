<div *ngIf="isInputVisible">

  <div class="flex items-center space-x-2">
    <input (click)="$event.stopPropagation();"
      class="block appearance-none w-fit bg-white border hover:border-gray-500 px-4 py-2  rounded shadow leading-tight focus:outline-none focus:shadow-outline"
      type="text" [placeholder]="tableColumn.name" [formControl]="filterControl" style="width: 80%;">

    <span  (click)="filterControl.setValue('');toggleInputVisibility()"  class="material-symbols-outlined cursor-pointer text-gray-500 hover:text-gray-700" style="width: 20%;">
      close
    </span>
  </div>
</div>


<div *ngIf="!isInputVisible">
  <div mat-button [class.text-right]="tableColumn.position === 'right'"
    class="filter-header-title" [matTooltip]="(tableColumn.name)">
    <mat-icon class="material-symbols-outlined " (click)="toggleInputVisibility()">
      search
    </mat-icon>
    {{(tableColumn.name)}}
  </div>
</div>
