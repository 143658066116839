
<div class=" inline-flex m-auto mt-3 items-center ">
  <span> <mat-icon class="    mr-3 text-primary-500 dark:text-white text-8xl h-16 w-16 ">list</mat-icon></span>
  <h1   *ngIf="iscreate "  class="font-bold  text-2xl  text-primary-500 dark:text-white ">New Project</h1>
  <h1   *ngIf="!iscreate "  class="font-bold  text-2xl   text-primary-500 dark:text-white ">Update Project {{form.get('name')?.value}}</h1>
</div>

<div mat-dialog-content  >
  <form [formGroup]="form">
    <mat-form-field appearance="fill" class="w-full">
      <mat-label>Project name</mat-label>
      <input matInput formControlName="name">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-full">
      <mat-label>Status</mat-label>
      <mat-select formControlName="status">
        <mat-option  *ngFor="let state of projectStatusOptions" [value]="state.value">{{state.value}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-full">
      <mat-label>Has alert</mat-label>
      <mat-select formControlName="hasAlerts">
        <mat-option selected [value]="true">Yes</mat-option>
        <mat-option [value]="false">No</mat-option>
      </mat-select>
    </mat-form-field>
    
  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="onSubmit()" [disabled]="!form.valid">Save</button>
</div>