<ng-container  class="flex flex-row justify-end">
  @if (tableColumn.actions.length>0 && containsActionsToHide()) {
    <button mat-icon-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"
      (click)="$event.stopPropagation(); $event.preventDefault(); menuAction()">
      <mat-icon class="material-symbols-outlined ">more_vert</mat-icon>
    </button>
  }
  <mat-menu #menu="matMenu">
    @for (action of tableColumn.actions; track action) {
      <div
        [matTooltip]=" disableBtn(action) ? (action.disableReasonText) : null">
        @if (showBtn(action)) {
          <button class="action-row" [disabled]="disableBtn(action)" mat-menu-item
            (click)="$event.stopPropagation(); $event.preventDefault(); onAction(action.action)">
            @if (action.materialIconName) {
              <mat-icon class="material-symbols-outlined "> {{action.materialIconName}} </mat-icon>
            }
            <span> {{(action.label)}} </span>
          </button>
        }
      </div>
    }

  </mat-menu>
</ng-container>
