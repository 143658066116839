import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CardService {


  CardsAllUrl= "cards/zones/";
  AddCardUrl= "cards/";
  DeleteCardUrl= "cards/";
  UpdateCardUrl= "cards/";


  constructor(private http: HttpClient) { 
  }
  
  getAllCards(zone_id:any): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.get<any>(`${environment.ApiUrl+this.CardsAllUrl+zone_id}`,{ headers: headers });
    
  }

  addCard(data:any): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.post<any>(`${environment.ApiUrl+this.AddCardUrl}`,data,{ headers: headers });
    
  }

  updateCard(data:any): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.put<any>(`${environment.ApiUrl+this.UpdateCardUrl+data.id}`,data,{ headers: headers });
    
  }

  deleteCard(Card_id:number): Observable<any> {

    const headers = new HttpHeaders({
      'accept': '*/*' ,
      'Content-Type': 'application/json'
    });

    return this.http.delete<any>(`${environment.ApiUrl+this.DeleteCardUrl+Card_id}`,{ headers: headers });
    
  }
}
