  <div class="h-screen flex flex-col" [class.dark]="isDarkMode$ | async">
    @if (isConnected) {
      <mat-sidenav-container class="flex-grow">
        <mat-sidenav #sidenav [mode]="sidenavMode" opened
          class="w-64  border-primary-700 dark:border-gray-700 bg-primary-500 dark:bg-gray-800">
          <app-sidenav ></app-sidenav>
        </mat-sidenav>


        <mat-sidenav-content class="bg-gray-50 dark:bg-gray-900">
          <app-header  (menuToggled)="sidenav.toggle()"></app-header>

          <div class="px-6" >
            <router-outlet></router-outlet>
          </div>

        </mat-sidenav-content>
      </mat-sidenav-container>
    }@else {
      <router-outlet></router-outlet>
    }

  </div>
