import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-status-cell',
  templateUrl: './status-cell.component.html',
  styleUrls: ['./status-cell.component.scss'],
  standalone:true,
  imports:[
    CommonModule,
    MatTooltipModule,
  ]
})
export class StatusCellComponent {
  @Input() tableColumn: any;
  @Input() element: any;
  @Output() actionEmittedEvent: EventEmitter<any> = new EventEmitter();

  onAction(actionn: any, event?: { stopPropagation: () => void; preventDefault: () => void; }): void {
    if (this.tableColumn.isClickable) {
      event?.stopPropagation();
      event?.preventDefault();
    }
    const act = {
      action: actionn,
      row: this.element,
    };
    this.actionEmittedEvent.emit(act);
  }

  getStatus(value: { toString: () => string; }): string {
    return value ? value.toString().replaceAll('_', '-').toLocaleLowerCase() : '';
  }

}
