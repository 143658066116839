import { createDateSimpleColumn, createFilterColumn, createSimpleColumn, createStatusColumn, createTextColumn, createUrlColumn } from "../../shared/config-table";
import { TableColumn } from "../../shared/models/table-columns.model";

export const displayedColumns: TableColumn[] = [
  createSimpleColumn('Id', 'id',true),
  createSimpleColumn('Subject','subject',true),
  createSimpleColumn('Description', 'description',true),
  createFilterColumn('Status','status',[{value:'1- High', label:'1- High'},
    {value:'2- Medium', label:'2- Medium'},
    {value:'3- Low', label:'3- Low'}],true),
  createDateSimpleColumn('Created on','created_on',true),
  createFilterColumn('Priority', 'priority', [{value:'1-A_AFFINER', label:'1-A_AFFINER'},
    {value:'2-A_DEVELOPPER', label:'2-A_DEVELOPPER'},
    {value:'3-EC_DEV', label:'3-EC_DEV'},
    {value:'4-A_RU', label:'4-A_RU'},
    {value:'5-EC_RU', label:'5-EC_RU'},
    {value:'6-A_VALIDER', label:'6-A_VALIDER'},
    {value:'7-VALIDE', label:'7-VALIDE'}],true),
  createUrlColumn('URL', 'url', true),
];
