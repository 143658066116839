<div class="inline-flex m-auto mt-3 items-center">
  <span>
    <mat-icon class="mr-3 text-primary-500 dark:text-white text-8xl h-16 w-16">person_add</mat-icon>
  </span>
  <h1 *ngIf="isCreate" class="font-bold text-2xl text-primary-500 dark:text-white">Assigne role</h1>
  <h1 *ngIf="!isCreate" class="font-bold text-2xl text-primary-500 dark:text-white">Assigne role {{form.get('username')?.value}}</h1>
</div>

<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field appearance="fill" class="w-full">
      <mat-label>Select Role</mat-label>
      <mat-select formControlName="role">
        <mat-option *ngFor="let role of roles" [value]="role">
          {{ role.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </form>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="onSubmit()" [disabled]="!form.valid">Save</button>
</div>
