import { Component,inject } from '@angular/core';
import {  MatButtonModule } from '@angular/material/button';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectService } from '../../services/project/project.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { AddProjectComponent } from '../../components/add-project/add-project.component';
import { MatDialog} from '@angular/material/dialog';
import { RouterLink } from '@angular/router';
import { ThemeService } from '../../services/theme.service';
import { FormsModule, } from '@angular/forms';
import { FilterDataPipe } from '../../shared/pipes/filter-data.pipe';
import { CustomDialogComponent } from '../../shared/components/custom-dialog/custom-dialog.component';

@Component({
  selector: 'app-projects',
  standalone: true,
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  imports: [FormsModule, RouterLink,MatIconModule, CommonModule,MatButtonModule,FilterDataPipe],

})

export class ProjectsComponent {

  searchText: string = '';
  breakpoint:number = 4;
  projects: any;
  IsDarkMode:boolean = false;
  readonly deleteDialog = inject(MatDialog);
  readonly dialog = inject(MatDialog);

  constructor(private readonly darkMode:ThemeService,private readonly projectService:ProjectService,private readonly _snackBar: MatSnackBar){
  }

  configSnakebar = {
    duration: 30000,
    panelClass: ['warning-snackbar'],
    verticalPosition: 'top'
  }

  ngOnInit() {

    this.darkMode.isDarkMode$.subscribe(value =>{
      this.IsDarkMode = value;
    });

    this.breakpoint = (window.innerWidth <= 768) ? 1 : 4;

    // this.openSnackBar('You have an alert from camera number 3 you have to checkOut','close')

    setTimeout(() => {
      this.getProjects();
    }, 500);


  }

  onResize(event:any) {
    this.breakpoint = (event.target.innerWidth <= 768) ? 1 : 4;
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  getProjects() {
    if(localStorage.getItem('CamAppUser')){
      let user = JSON.parse(localStorage.getItem('CamAppUser')??'');
      if (this.isAdmin()) {
        this.projectService.getAllProjects().subscribe(
          (response) => {
            this.projects = response;
          }
        );
        return;
      }
      this.projectService.getAllProjectsByUser(user.id).subscribe(
        (response) => {
          this.projects = response;
        }
      );
    }else{
      this.projectService.getAllProjects().subscribe(
        (response) => {
        this.projects = response;
        }
      );
    }


  }
  isAdmin(): boolean {
    let user= JSON.parse(localStorage.getItem('CamAppUser')??'')
    return user.roles.some((role: any) => role.name === 'ADMIN');
  }
  isRespo(): boolean {
    let user= JSON.parse(localStorage.getItem('CamAppUser')??'')
    return user.roles.some((role: any) => role.name === 'RESPONSABLE SITE');
  }

  openCreateDialog(): void {
     this.dialog.open(AddProjectComponent, {
      width: '75vw',
      panelClass: this.IsDarkMode ? 'dark' : ''
    }).afterClosed().subscribe((result) => {
        this.getProjects();
    });
  }

  openUpdateDialog(project:any): void {
    this.dialog.open(AddProjectComponent, {
     width: '75vw',
     data:project,
     panelClass: this.IsDarkMode ? 'dark' : ''
   }).afterClosed().subscribe((result) => {
       this.getProjects();
   });
 }


  openDeleteDialog(project_id:number,enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.deleteDialog.open(CustomDialogComponent, {
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        project_id: project_id ,
        title: "Delete site",
        message: "Are you sure you want to delete this site?",},
      panelClass: this.IsDarkMode ? 'dark responsive-dialog' : 'responsive-dialog'
    }).afterClosed().subscribe((result) => {
      if (result) {
        this.deleteProject(project_id);
      }

    });

  }
  deleteProject(project_id:number){
    this.projectService.deleteProject(project_id).subscribe(
      (response) => {
        if(response.message){
          this.openSnackBar(response.message,"close");
          this.getProjects();
        }
      }
    );
  }

}

