<div *ngIf="cameras; else shimmerGrid" class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-4 mx-2 dark:text-white text-gray-950">
  <div *ngFor="let camera of cameras" class="bg-white dark:bg-slate-950 rounded-lg shadow-md">
    <div class="p-4">
      <div class="wrap">
        <img class="w-full h-[45vh] object-cover rounded" [src]="camera.thumbnailUrl | safe" alt="Camera thumbnail">
      </div>
    </div>
    <div class="p-2 text-center">
      <b>{{ camera.name }}</b>
    </div>
  </div>
</div>

<ng-template #shimmerGrid>
  <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-4 mx-2 dark:text-white text-gray-950">
    <div *ngFor="let i of [1, 2, 3,4, 1, 2, 3,4]" class="rounded-lg shadow-md bg-gray-300 dark:bg-gray-500  animate-pulse h-[40vh]">
      <div class="p-4">
        <div class="wrap">
        </div>
      </div>
      <div class="p-2 text-center">
      </div>
    </div>
  </div>
</ng-template>

