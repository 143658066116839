import { CommonModule } from '@angular/common';
import {
  Component,
  ViewChild,
  ElementRef,
  HostListener,
  inject,
  ChangeDetectionStrategy,
  Inject,
} from '@angular/core';
import { MatButton, MatButtonModule, MatMiniFabButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ActivatedRoute, RouterModule } from '@angular/router';
import html2canvas from 'html2canvas';
import {
  NguCarousel,
  NguCarouselConfig,
  NguCarouselDefDirective,
  NguCarouselNextDirective,
  NguCarouselPrevDirective,
  NguItemComponent,
  NguTileComponent
} from '@ngu/carousel';
import { MatCardModule } from '@angular/material/card';
import { CameraService } from '../../services/camera/camera.service';
import { CardService } from '../../services/card/card.service';
import { SensorService } from '../../services/sensor/sensor.service';
import { AddCameraComponent } from '../../components/add-camera/add-camera.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-cameras',
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    MatProgressSpinnerModule,
    MatGridList,
    MatGridTile,
    MatIconModule,
    MatButton,
    MatMiniFabButton,
    MatDivider,
    NguCarousel,
    NguTileComponent,
    NguCarouselNextDirective,
    NguCarouselPrevDirective,
    MatCardModule,
    NguItemComponent,
    NguCarouselDefDirective],
  templateUrl: './cameras.component.html',
  styleUrl: './cameras.component.scss'
})
export class CamerasComponent {

  @ViewChild('videoElement') videoElement!: ElementRef<HTMLImageElement>;
  @ViewChild('videoContainer') videoContainer!: ElementRef<HTMLDivElement>;

  IsDarkMode:boolean = false;
  isPlaying = true; // Track if the stream is playing
  zoomLevel = 1; // Initial zoom level
  zoomStep = 0.1; // Amount of zoom per step
  isDragging = false; // To track dragging state
  startX = 0; // Starting X position for dragging
  startY = 0; // Starting Y position for dragging
  startTransformX = 0; // Starting X position for panning
  startTransformY = 0; // Starting Y position for panning
  takingScreen = false; // If taking Screen this will be true to show loader
  videoPresent = false; // If taking Screen this will be true to show loader
  currentTime: any = null;
  zoneId: any = null;
  zoneName: string | null = "";
  cameras: any = null;
  sensors: any = null;
  carouselTileItems: any = null;
  refresh:boolean=false;
  currentIndex = 0;
  intervalId: any;
  groupedCarouselItems: any[][] = [];

  carouselTile: NguCarouselConfig = {
    grid: { xs: 3, sm: 3, md: 3, lg: 6, xl: 6, all: 0 },
    slide: 1,
    speed: 250,
    point: {
      visible: false
    },
    load: 2,
    velocity: 0,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)'
  };

  selectedCamera:any = null;

  url = '';//http://192.168.1.78:8000/video_feed

  readonly deleteDialog = inject(MatDialog);
  readonly dialog = inject(MatDialog);

  constructor(private darkMode:ThemeService,private cardservice: CardService, private sensorservice: SensorService, private route: ActivatedRoute, private cameraservice: CameraService) { }

  ngOnInit(): void {

    this.darkMode.isDarkMode$.subscribe(value =>{
      this.IsDarkMode = value;
    });

    this.route.paramMap.subscribe(params => {
      this.zoneId = params.get('zone_id');
      this.zoneName = params.get('zone_name');
      setTimeout(() => {
        this.getCameras();
        this.getSensorsZones();
      }, 500);
    });

    setInterval(() => {
      this.getSensorsZones();
    }, 10000);

    /**Show current time */
    setInterval(() => {
      const now = new Date();
      this.currentTime = now.toLocaleString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });
    }, 1000);

  }

  startCarousel(): void {
    this.intervalId = setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.carouselTileItems.length;
    }, 3000); // Adjust the interval as needed
  }

  groupCarouselItems(): void {
    for (let i = 0; i < this.carouselTileItems.length; i += 6) {
      this.groupedCarouselItems.push(this.carouselTileItems.slice(i, i + 6));
    }
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  getSensorsZones() {
    // this.refresh = true;
    this.cardservice.getAllCards(this.zoneId).subscribe(
      (rsp) => {
        if (rsp && rsp.length>0) {
          let allSensors: any[] = [];  // Array to accumulate all sensors

          if(rsp && rsp?.length > 0){

            rsp.forEach((element: { id: any; }) => {
              this.sensorservice.getAllSensors(element.id).subscribe(
                (response) => {
                  if (response) {
                    allSensors = allSensors.concat(response);  // Concatenate sensors from each card
                    this.carouselTileItems = allSensors;
                    this.groupCarouselItems();
                    this.startCarousel();
                  }
                }
              );
            });
            this.refresh = true;

            setTimeout(() => {   this.refresh = false; }, 1000);
          }
          else
          this.carouselTileItems = [];
        }
      }
    );

  }

  getCameras() {
    this.cameraservice.getAllCameras(this.zoneId).subscribe(
      (response) => {
        if (response) {
          this.cameras = response;
        }
      }
    );
  }

  ngAfterViewInit(): void {
    this.startVideoStream();
  }

  zoomIn() {
    this.zoomLevel += this.zoomStep;
    this.applyZoom();
  }

  zoomInit() {
    const img = this.videoElement.nativeElement;
    img.style.transform = `scale(1)`;
    img.style.transformOrigin = `100% 100%`;
    img.style.left = `0px`;
    img.style.top = `0px`;
    this.zoomLevel = 1;
  }

  zoomOut() {
    this.zoomLevel = Math.max(1, this.zoomLevel - this.zoomStep); // Ensure zoom level doesn't go below 1
    this.applyZoom();
  }

  startCamera(camera: any) {
    this.videoPresent = false;
    this.videoElement.nativeElement.src = '';
    this.url = camera.thumbnailUrl;
    this.selectedCamera = camera;
    this.startVideoStream();
  }

  private startVideoStream(): void {
    if (this.videoElement && this.videoElement.nativeElement) {

      // Set the video source URL for MJPEG stream
      this.videoElement.nativeElement.src = this.url;

      this.videoElement.nativeElement.classList.add("image-content");
      this.videoElement.nativeElement.classList.remove("image-content-no");

      // Event listener for when the video is ready to play
      this.videoElement.nativeElement.oncanplay = () => {

        this.videoElement.nativeElement.classList.add("image-content");
        this.videoElement.nativeElement.classList.remove("image-content-no");
        this.videoPresent = true;
      };

      // Optional: Handle errors
      this.videoElement.nativeElement.onerror = () => {
        this.videoPresent = true;
        this.videoElement.nativeElement.classList.add("image-content-no");
        this.videoElement.nativeElement.classList.remove("image-content");
        this.videoElement.nativeElement.src =
          '../../assets/images/no-video.png';
      };
    } else {
      this.videoPresent = true;
    }
  }

  onImageLoad(): void {
    this.videoPresent = true;
  }

  onImageError(): void {
    this.videoPresent = true;
    this.videoElement.nativeElement.src =
      '../../assets/images/no-video.png';
  }


  togglePlayPause(): void {
    if (this.isPlaying) {
      this.videoElement.nativeElement.src = ''; // Stop the stream
    } else {
      this.startVideoStream(); // Restart the stream
    }
    this.isPlaying = !this.isPlaying;
  }

  toggleFullScreen(): void {
    const img = this.videoElement.nativeElement;
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      img.requestFullscreen();
    }
  }

  private applyZoom() {
    const img = this.videoElement.nativeElement as HTMLImageElement;
    if (img) {
      img.style.transform = `scale(${this.zoomLevel})`;
      // Keep the container's overflow hidden
      this.videoContainer.nativeElement.style.overflow = 'hidden';
    }
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    this.isDragging = true;
    this.startX = event.clientX;
    this.startY = event.clientY;
    this.startTransformX = parseInt(this.videoElement.nativeElement.style.left) || 0;
    this.startTransformY = parseInt(this.videoElement.nativeElement.style.top) || 0;
    this.videoElement.nativeElement.style.cursor = 'grabbing';
    event.preventDefault(); // Prevent default scroll behavior
  }

  @HostListener('mouseup', ['$event'])
  onMouseUp(event: MouseEvent): void {
    if (this.isDragging) {
      this.isDragging = false;
      this.videoElement.nativeElement.style.cursor = 'grab';
    }
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event: MouseEvent): void {
    if (this.isDragging) {


      const diffX = event.clientX - this.startX;
      const diffY = event.clientY - this.startY;

      let newLeft = this.startTransformX + diffX;
      let newTop = this.startTransformY + diffY;

      const containerRect = this.videoContainer.nativeElement.getBoundingClientRect();
      const imageRect = this.videoElement.nativeElement.getBoundingClientRect();
      const rect = this.videoContainer.nativeElement.getBoundingClientRect();


      // Ensure the image stays within the container's bounds
      if (newLeft > 0) {
        newLeft = 0;
      } else if (newLeft < containerRect.width - imageRect.width) {//- containerRect.left
        newLeft = containerRect.width - imageRect.width;
      }

      if (newTop > 0) {
        newTop = 0;
      } else if (newTop < containerRect.height - imageRect.height) {//- containerRect.top
        newTop = containerRect.height - imageRect.height;
      }

      this.videoElement.nativeElement.style.left = newLeft + 'px';
      this.videoElement.nativeElement.style.top = newTop + 'px';


    }

  }

  @HostListener('wheel', ['$event'])
  onMouseWheel(event: WheelEvent): void {
    const containerRect = this.videoContainer.nativeElement.getBoundingClientRect();

    if (this.isCursorInContainer(event, containerRect)) {
      event.preventDefault(); // Prevent default scroll behavior

      const isZoomIn = event.deltaY < 0;
      const zoomChange = isZoomIn ? this.zoomStep : -this.zoomStep;
      this.zoomLevel = Math.max(1, this.zoomLevel + zoomChange); // Update zoom level

      const mouseX = event.clientX - containerRect.left;
      const mouseY = event.clientY - containerRect.top;

      this.updateZoom(mouseX, mouseY, isZoomIn);

    }
  }

  private isCursorInContainer(event: MouseEvent, containerRect: DOMRect): boolean {
    const mouseX = event.clientX;
    const mouseY = event.clientY;

    return (
      mouseX >= containerRect.left &&
      mouseX <= containerRect.right &&
      mouseY >= containerRect.top &&
      mouseY <= containerRect.bottom
    );
  }

  private updateZoom(mouseX: number, mouseY: number, isZoomIn: boolean): void {
    if (this.videoElement.nativeElement && this.videoContainer.nativeElement) {
      const img = this.videoElement.nativeElement;
      const container = this.videoContainer.nativeElement;

      const scale = this.zoomLevel;
      const containerRect = container.getBoundingClientRect();
      const imgRect = img.getBoundingClientRect();

      let transformOriginX: number;
      let transformOriginY: number;

      if (isZoomIn) {
        transformOriginX = (mouseX / containerRect.width) * this.zoomLevel;
        transformOriginY = (mouseY / containerRect.height) * this.zoomLevel;
      } else {
        const currentTransformOrigin = img.style.transformOrigin.split(' ');
        transformOriginX = parseFloat(currentTransformOrigin[0]) || 50;
        transformOriginY = parseFloat(currentTransformOrigin[1]) || 50;
      }

      img.style.transformOrigin = `${transformOriginX}% ${transformOriginY}%`;
      img.style.transform = `scale(${scale})`;

      // Calculate new left and top positions to ensure the image stays within the container
      const scaledWidth = imgRect.width * scale;
      const scaledHeight = imgRect.height * scale;

      const maxLeft = Math.min(0, containerRect.width - scaledWidth);
      const maxTop = Math.min(0, containerRect.height - scaledHeight);

      const newLeft = Math.min(0, Math.max(maxLeft, img.offsetLeft));
      const newTop = Math.min(0, Math.max(maxTop, img.offsetTop));

      img.style.left = `${newLeft}px`;
      img.style.top = `${newTop}px`;

      container.style.overflow = 'hidden';
    }
  }

  captureScreenshot(): void {
    this.takingScreen = true;
    const container = this.videoContainer.nativeElement;
    setTimeout(() => {
      html2canvas(container, {
        useCORS: true,
        allowTaint: true,
      }).then((canvas) => {
        this.downloadImage(canvas.toDataURL('image/png'), `screenshot_${this.currentTime}.png`);
        this.startVideoStream();
        this.takingScreen = false;
      });
    }, 500);  // Adjust the delay as needed
  }

  downloadImage(dataUrl: string, filename: string) {
    const a = document.createElement('a');
    a.href = dataUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  openCreateDialog(): void {
    this.dialog.open(AddCameraComponent, {
     width: '75vw',
     panelClass: this.IsDarkMode ? 'dark' : '',
     data: {'selected_zone':this.zoneId}
   }).afterClosed().subscribe((result) => {
       this.getCameras();
   });
  }

  openUpdateDialog(): void {
    this.dialog.open(AddCameraComponent, {
      width: '75vw',
      panelClass: this.IsDarkMode ? 'dark' : '',
      data:this.selectedCamera
    }).afterClosed().subscribe((result) => {
        this.getCameras();
    });
  }


  openDeleteDialog(camera_id:any,enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.deleteDialog.open(DialogDeleteCamera, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: { camera_id: camera_id }
    }).afterClosed().subscribe((result) => {
        this.getCameras();
    });
  }

}

@Component({
 selector: 'delete-camera-dialog',
 templateUrl: '../../shared/templates/delete-camera-dialog.html',
 standalone: true,
 imports: [MatButtonModule,MatDialogModule, MatDialogActions, MatDialogClose, MatDialogTitle, MatDialogContent],
 changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogDeleteCamera {
 readonly dialogRef = inject(MatDialogRef<DialogDeleteCamera>);

 constructor(@Inject(MAT_DIALOG_DATA) public data: { camera_id: number }
 ,private cameraService:CameraService,private _snackBar: MatSnackBar,private darkMode:ThemeService){

 }

 deleteCamera(camera_id:number){
   this.cameraService.deleteCamera(camera_id).subscribe(
     (response) => {
       if(response.message){
         this.openSnackBar(response.message,"close")
         this.dialogRef.close(true);
       }
     }
   );
 }

 openSnackBar(message: string, action: string) {
   this._snackBar.open(message, action);
 }

}
