<div class="py-4">
  <div class="px-4 mb-6">
    <img src="assets/images/logo_sidebar.svg" alt="Logo" class="h-32  m-auto mt-3">
  </div>
  <mat-nav-list>
    <a mat-list-item
       *ngFor="let item of navItems"
       (click)="onNavItemClicked(item)"
       [routerLinkActive]="item.route ? 'bg-primary-300' : ''"
       class="text-gray-600 hover:bg-primary-300 mt-1"
       [ngClass]="item.addedClass??''">
      <mat-icon matListItemIcon  class="mat-18 text-white">{{item.icon}}</mat-icon>
      <span matListItemTitle class="text-white">{{item.label}}</span>
    </a>
  </mat-nav-list>
</div>

