<div class="inline-flex m-auto mt-3 items-center">
  <span>
    <mat-icon class="mr-3 text-primary-500 dark:text-white text-8xl h-16 w-16">person_add</mat-icon>
  </span>
  <h1 *ngIf="isCreate" class="font-bold text-2xl text-primary-500 dark:text-white">New User</h1>
  <h1 *ngIf="!isCreate" class="font-bold text-2xl text-primary-500 dark:text-white">Update User {{form.get('username')?.value}}</h1>
</div>

<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field appearance="fill" class="w-1/2 md:p-1 max-md:w-full">
      <mat-label>Username</mat-label>
      <input matInput formControlName="username">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-1/2 md:p-1 max-md:w-full">
      <mat-label>Email</mat-label>
      <input matInput formControlName="email">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-1/2 md:p-1 max-md:w-full">
      <mat-label>First Name</mat-label>
      <input matInput formControlName="firstName">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-1/2 md:p-1 max-md:w-full">
      <mat-label>Last Name</mat-label>
      <input matInput formControlName="lastName">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-1/2 md:p-1 max-md:w-full">
      <mat-label>Password</mat-label>
      <input matInput type="password" formControlName="password">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-1/2 md:p-1 max-md:w-full">
      <mat-label>Confirm Password</mat-label>
      <input matInput type="password" formControlName="confirmPassword">
    </mat-form-field>

    <!-- <mat-checkbox formControlName="enabled">Enabled</mat-checkbox>
    <mat-checkbox formControlName="emailVerified">Email Verified</mat-checkbox> -->
  </form>
</div>

<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="onSubmit()" [disabled]="!form.valid">Save</button>
</div>
