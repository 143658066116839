import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterData',
  standalone: true
})

export class FilterDataPipe implements PipeTransform {
  transform(datas: any[], searchText: string): any[] {

    if (!datas) {
      return [];
    }
    if (!searchText) {
      return datas; 
    }
    return datas.filter((data) =>
      {
        console.log(data);
      
        data.name.toLowerCase().includes(searchText.toLowerCase())
      }
    );
  }
}

