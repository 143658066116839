
<div class=" inline-flex m-auto mt-3 items-center ">
  <span> <mat-icon class="    mr-3 text-primary-500 dark:text-white text-8xl h-16 w-16 ">broadcast_on_home</mat-icon></span>
  <h1   *ngIf="iscreate "  class="font-bold  text-2xl  text-primary-500 dark:text-white ">Add new Sensor type</h1>
  <h1   *ngIf="!iscreate "  class="font-bold  text-2xl   text-primary-500 dark:text-white ">Update Sensor type {{form.get('name')?.value}}</h1>
</div>

<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field appearance="fill" class="w-1/2 md:p-1 max-md:w-full">
      <mat-label>Name </mat-label>
      <input matInput formControlName="name">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-1/2 md:p-1 max-md:w-full">
        <mat-label>Unit </mat-label>
        <input matInput formControlName="unit">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-1/2 md:p-1 max-md:w-full">
      <mat-label>Value Type </mat-label>
      <mat-select formControlName="type">
        <mat-option  [value]="type" *ngFor="let type of types">{{type}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-1/2 md:p-1 max-md:w-full">
      <mat-label>Max Value </mat-label>
      <input matInput formControlName="max_value">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-1/2 md:p-1 max-md:w-full">
      <mat-label>Min Value </mat-label>
      <input matInput formControlName="min_value">
    </mat-form-field>

    <mat-form-field appearance="fill" class="w-1/2 md:p-1 max-md:w-full">
        <mat-label>Alert Value </mat-label>
        <input matInput formControlName="alert_value">
    </mat-form-field>


  </form>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button (click)="onSubmit()" [disabled]="!form.valid">Save</button>
</div>
