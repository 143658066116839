<mat-dialog-content class="p-0 h-full">
  <div class=" px-4 pb-4 pt-5 sm:p-6 sm:pb-4 flex flex-col">
    <div class="flex items-center justify-center">
      <mat-icon class="material-symbols-outlined  bounce text-red-800">
        cancel</mat-icon
      >
    </div>
    <div class="mt-4 text-center">
      <h3
        class="text-base font-semibold leading-6 text-red-800"
        id="modal-title"
      >
      {{data.title}}
      </h3>
      <div class="mt-2">
        <p class="text-sm text-gray-500">
          {{data.message}}
        </p>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="flex justify-between py-4" style="padding-left: 2rem !important;padding-right: 2rem !important;">
  <button
    mat-flat-button
    [mat-dialog-close]="false"
    class="btn-cancel text-primary-800"
  >
    No
  </button>
  <button
    class="btn-primary"
    mat-flat-button
    [mat-dialog-close]="true"
    cdkFocusInitial
    [mat-dialog-close]="data.id"
  >
  Yes
  </button>
</mat-dialog-actions>
