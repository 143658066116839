
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-filter-header',
  templateUrl: './filter-header.component.html',
  styleUrls: ['./filter-header.component.scss'],
  standalone: true,
  imports: [
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule
]
})
export class FilterHeaderComponent implements OnInit {
  @Input() tableColumn: any;
  @Output() filterChangedEvent: EventEmitter<any> = new EventEmitter();
  selectedFilters: string[] = [];
  filterForm!: FormGroup;

  constructor(private _fb: FormBuilder,) { }

  ngOnInit(): void {
    this.filterForm = this._fb.group({
      options: new FormArray(
        this.tableColumn.filterHeaderOption.map((item: any) =>
          new FormControl(false)
        ),
      ),
    },);
  }


  filterFormControls = (): AbstractControl[] => (this.filterForm?.controls['options'] as FormArray).controls;

  filterChanged(event: any, value: string, index: number): void {

    if (event.checked && this.tableColumn.singleSelect) {
      this.selectedFilters = [];
      this.filterFormControls().forEach((control, currIndex) => {
        if (index !== currIndex) control.setValue(false);
      })
    }

    if (event.checked) {
      this.selectedFilters.push(value);
    } else {
      this.selectedFilters.splice(this.selectedFilters.indexOf(value), 1);
    }
    this.filterChangedEvent.emit(this.selectedFilters);
  }

}
